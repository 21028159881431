import { Helper } from '../../../util';
import './style.scss';

interface ReviewProductCardProps {
    product: any,
    descriptionReference: string,
    unitValueReference: string,
    quantityReference: string,
    pathImageReference?: string
}

export default function ReviewProductCard(props: ReviewProductCardProps){
    const { product } = props;
    
    const { unitValueReference, quantityReference, pathImageReference, descriptionReference } = props;
    
    return (
        <div className="reviewProductCardContainer spacedContainer">
            <div className="orderProductsItem alignedContainer">
                {pathImageReference && <div className="defaultThumbnail">
                    <img src={product[pathImageReference]} alt="Imagem do produto" />
                </div>}
                <div className="orderProductsItemInfos">
                    <h6>{product[descriptionReference]}</h6>
                    <div className="alignedContainer">
                        <span>{product[quantityReference]}x</span>
                        <span>{Helper.formatCurrency(product[unitValueReference])}</span>
                    </div>
                </div>
            </div>
        </div>
    )
} 