import { useState, useRef, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import LoaderContext from "../../../../../context/loader";
import { PedidosProductType, PedidosStatusType, PedidosType, TransportadoraType } from "../../../../../types";

import TextBtn from "../../../../../components/buttons/text-btn";
import OrderStatus from "../order-status";
import OrderModal from "../order-modal";
import ReviewProductCard from "../../../../../components/cards/review-product-card";

import './style.scss';
import { Helper } from "../../../../../util";
import { CartApi, ProfileApi } from "../../../../../services/api-routes";

interface OrderCardProps {
    order: PedidosType,
}

export default function OrderCard(props: OrderCardProps){
    const { order } = props;

    const { setLoading } = useContext(LoaderContext);

    const [is_open, setIsOpen] = useState(false);
    const [is_products_modal_open, setIsProductsModalOpen] = useState(false);
    const [is_status_modal_open, setIsStatusModalOpen] = useState(false);
    const [status_list, setStatusList] = useState<PedidosStatusType[]>([]);
    const [status, setStatus] = useState(false);
    const [carrying_company, setCarryingCompany] = useState<TransportadoraType | null>(null);

    const contentRef = useRef<HTMLDivElement>(null);

    const getStatusList = async () => {
        const resp = await ProfileApi.delivery_status.show(Helper.getFornecedorDocument(), order.NumeroPedido, 1, 10);
        if (resp && !resp.Message) setStatusList(resp.data);
    }

    const getCarryingCompanyById = async (id: number) => {
        const resp = await CartApi.carrier.show(id);
        if (resp && !resp.Message) setCarryingCompany(resp);
    }

    const handleMultiplesPromises = async () => { 
      var resps = [
        getStatusList(),
        getCarryingCompanyById(order.IdTransportadora)
      ];
  
      await Promise.all(resps)
      .then(() => {})
      .catch((e) => {
        toast.error("Erro ao carregar dados do pedido!");
        //console.log('Error', e);
      });
    }

    const getData = useCallback(async () => {
        setLoading(true);
        setStatus(order.IdTipoPagamento !== 0);
        await handleMultiplesPromises();
        setLoading(false);
      }, [setLoading, order])
    
      useEffect(() => {    
        (async () => await getData())();
    }, [getData]);
    
    return (
        <div className="orderCardContainer">
            <div 
                ref={contentRef} 
                style={{height: is_open ? contentRef?.current?.scrollHeight : 140}} 
                className={`orderCardContent ${status ? 'approved' : 'pendent'}`}
            >
                <div className="orderCardHeader">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <p>Pedido</p>
                                <h1>{order.NumeroPedido}</h1>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <p>Status</p>
                                <div className="orderStatusTag">
                                    {status ? 'Aprovado' : 'Pendente'}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <p>Data do Pedido</p>
                                <h3>{moment(order.DataPedido).format('DD/MM/YYYY')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orderCardHiddenInfos">
                    <div className="defaultDivider" />
                    <div className="alignRightContainer">
                        <TextBtn
                            text="Ver Detalhes"
                            onClick={() => setIsStatusModalOpen(true)}
                            customStyle={{fontWeight: 'bold', fontSize: window.innerWidth > 991 ? '15px' : '14px', marginTop: '16px'}}
                        />
                    </div>
                    <div className="orderCardBody">
                        <OrderStatus 
                            status_approved={status_list.find((status: PedidosStatusType) => status.OrdemFase === 1)}
                            status_packing={status_list.find((status: PedidosStatusType) => status.OrdemFase === 2)}
                            status_waiting_transport={status_list.find((status: PedidosStatusType) => status.OrdemFase === 3)}
                            status_in_transport={status_list.find((status: PedidosStatusType) => status.OrdemFase === 4)}
                            status_delivered={status_list.find((status: PedidosStatusType) => status.OrdemFase === 5)}
                            active_status={1}
                        />
                        <div className="alignedContainer">
                            <div className="groupItemMarginRight">
                                <p>Transportadora</p>
                                <h3>{carrying_company?.NomeTransportadora}</h3>
                            </div>
                            <div className="groupItemMarginRight">
                                <p>Previsão de Entrega</p>
                                <h3>{moment(order.DataPrevisãoEntrega).format('DD/MM/YYYY')}</h3>
                            </div>
                            <div>
                                <p>Código de Rastreio</p>
                                <h3>ABC1234</h3>
                            </div>
                        </div>
                    </div>
                    <div className="defaultDivider" />
                    <div className="orderCardFooter">
                        <div className="spacedContainer">
                            <TextBtn 
                                customContent={(
                                    <div className="alignedContainer orderProductBtn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 6H16C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6H6C4.9 6 4 6.9 4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8C20 6.9 19.1 6 18 6ZM10 10C10 10.55 9.55 11 9 11C8.45 11 8 10.55 8 10V8H10V10ZM12 4C13.1 4 14 4.9 14 6H10C10 4.9 10.9 4 12 4ZM16 10C16 10.55 15.55 11 15 11C14.45 11 14 10.55 14 10V8H16V10Z" fill="black"/>
                                        </svg>
                                        Ver Produtos do Pedido
                                    </div>
                                )}
                                onClick={() => setIsProductsModalOpen(true)}
                            />
                            <div className="alignedContainer">   
                                <h3>Total:</h3>
                                <h2>{Helper.formatCurrency(order.ValorPedido)}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button 
                className="openCardBtn"
                onClick={() => setIsOpen(oldState => !oldState)}
            >
                {is_open ? "Ocultar Detalhes" : "Ver Detalhes"}
            </button>
            <OrderModal 
                is_open={is_products_modal_open}
                setIsOpen={setIsProductsModalOpen}
                titleIcon={(
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6H16C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6H6C4.9 6 4 6.9 4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8C20 6.9 19.1 6 18 6ZM10 10C10 10.55 9.55 11 9 11C8.45 11 8 10.55 8 10V8H10V10ZM12 4C13.1 4 14 4.9 14 6H10C10 4.9 10.9 4 12 4ZM16 10C16 10.55 15.55 11 15 11C14.45 11 14 10.55 14 10V8H16V10Z" fill="black"/>
                    </svg>
                )}
                title="Produtos do Pedido"
                content={(
                    <div className="orderModalContent defaultScrollContainer">
                        {order.Produtos.map((product: PedidosProductType) => (
                            <ReviewProductCard
                                key={`cartReview-${product.Id}`}
                                product={product}
                                descriptionReference="DescricaoDoProduto"
                                unitValueReference="ValorUnitario"
                                quantityReference="Quantidade"
                                pathImageReference="PathImg"
                            />
                        ))}
                    </div>
                )}
            />
            <OrderModal 
                is_open={is_status_modal_open}
                setIsOpen={setIsStatusModalOpen}
                title="Detalhes do Rastreamento"
                content={(
                    <div className="orderModalContent defaultScrollContainer">
                        {status_list.map((status => (
                            <div className="status-list-item">
                                <p><strong>Status:</strong> {status.Descricao}</p>
                                <p><strong>Data:</strong> {moment(status.DataStatus).format('DD/MM/YYYY')}</p>                                
                            </div>
                        )))}
                    </div>
                )}
            />
        </div>
    )
} 