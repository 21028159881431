import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import './style.scss';
import { Helper } from "../../../util";
import { MainApi, ProfileApi } from "../../../services/api-routes";

import UserContext from "../../../context/user";
import LoaderContext from "../../../context/loader";
import { AddressType } from "../../../types";

import CardSelectItem from "../../../components/cards/card-select-item";
import CustomHeader from "../../../components/custom/custom-header";
import NewsletterSection from "../../../components/sections/newsletter-section";
import TextBtn from "../../../components/buttons/text-btn";
import FormInput from "../../../components/forms/form-input";
import SelectInput from "../../../components/forms/select-input";
import DefaultBtn from "../../../components/buttons/default-btn";


export default function PersonalInfoPage() {
    const { user, setUser } = useContext(UserContext);
    const { setLoading } = useContext(LoaderContext);

    const history = useHistory();

    const [tab_active, setTabActive] = useState('profile');
    const [addresses, setAddresses] = useState<AddressType[]>([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [genre, setGenre] = useState('');
    const [birth, setBirth] = useState('');
    const [cpf, setCpf] = useState('');
    const [phone, setPhone] = useState('');
    
    const [invalid_name, setInvalidName] = useState(false);
    const [invalid_email, setInvalidEmail] = useState(false);
    const [invalid_password, setInvalidPassword] = useState(false);
    const [invalid_genre, setInvalidGenre] = useState(false);
    const [invalid_birth, setInvalidBirth] = useState(false);
    const [invalid_phone, setInvalidPhone] = useState(false);
    const [invalid_cpf, setInvalidCpf] = useState(false);

    const getData = useCallback(async () => {
        if (user) {
            setName(user.NomeCliente);
            setEmail(user.Email);
            setPassword(user.Senha);
            setGenre(user.Sexo);
            setBirth(moment(user.DataNascimento).format('DD/MM/YYYY'));
            setCpf(user.IdClienteCPFCNPJ);
            setPhone(user.TelefoneCelular);
            const resp = await ProfileApi.address.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, 1, 20);
            if (resp) {
                if (resp.data.length > 0) setAddresses(resp.data);
            }
        }
        window.scrollTo(0, 0);
    }, [user]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getData();
            setLoading(false);
        })();
    }, [getData])

    const handleUpdateUser = async () => {
        setLoading(true);
        window.scrollTo(0, 0);
        if (user) {
            if (verifyParams()) {
                const date = birth.split('/');
                const body = {
                    Id: user.Id,
                    SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
                    IdClienteCPFCNPJ: cpf.replace(/[^\d]+/g,''),
                    NomeCliente: name,
                    DataNascimento: `${date[1]}/${date[0]}/${date[2]}`,
                    Ativo: true,
                    Sexo: genre,
                    TelefoneCelular: Helper.removePhoneMask(phone),
                    Email: email,
                    EmailValidado: false,
                    Login: email,
                    Senha: password,
                    CodePasswordRecovery: 0,
                    DateExpirationCode: ""
                };
                const resp = await MainApi.user.update(JSON.stringify(body));                
                if (resp === null) {
                    const updated_user = user;
                    updated_user.IdClienteCPFCNPJ = cpf.replace(/[^\d]+/g,'');
                    updated_user.NomeCliente = name;
                    updated_user.DataNascimento = birth;
                    updated_user.Sexo = genre;
                    updated_user.TelefoneCelular = Helper.removePhoneMask(phone);
                    updated_user.Email = email;
                    updated_user.Login = email;
                    updated_user.Senha = password;
                    setUser(updated_user);
                    toast.success('Perfil atualizado com sucesso!', {toastId: 'userUpdateSuccess'})
                }
                setLoading(false);
                return
            }
        }
      setLoading(false);
    }
  
    const verifyParams = () => {
        let invalid;  
        if (name === '') {
          setInvalidName(true);
          invalid = true;
          toast.error('Nome é obrigatório!', {toastId: 'nameError'});
        } 
        if (email === '') {
          setInvalidEmail(true);
          invalid = true;
          toast.error('E-mail é obrigatório!', {toastId: 'emailError'});
        } else if (!Helper.checkEmail(email)) {
          setInvalidEmail(true);
          invalid = true;
          toast.error('E-mail não é válido!', {toastId: 'emailInvalidError'});
        }
        if (cpf === '') {
          setInvalidCpf(true);
          invalid = true;
          toast.error('CPF é obrigatório!', {toastId: 'cpfError'});
        } else if (!Helper.checkCpf(cpf)) {
          setInvalidCpf(true);
          invalid = true;
          toast.error('CPF não é válido!', {toastId: 'cpfInvalidError'});
        }
        if (password === '') {
          setInvalidPassword(true);
          invalid = true;
          toast.error('Senha é obrigatória!', {toastId: 'passwordError'});
        }
        return !invalid;
    }

    const profile_container = (
        <>
            <h3 className="sectionTitle">Dados Pessoais</h3>
            <FormInput
                type="text"
                label='Nome'
                name='name'
                value={name}
                onChange={setName}
                required={true}
                invalid={invalid_name}
                setInvalid={setInvalidName}
            />
            <FormInput 
                type="e-mail"
                label='E-mail'
                name='email'
                value={email}
                onChange={setEmail}
                required={true}
                invalid={invalid_email}
                setInvalid={setInvalidEmail}
                disabled
            />
            <FormInput 
                type="password"
                label='Senha'
                name='password'
                value={password}
                onChange={setPassword}
                required={true}
                invalid={invalid_password}
                setInvalid={setInvalidPassword}
                customStyle={{maxWidth: '360px'}}
            />
            <SelectInput
                label='Gênero'
                name='genre'
                value={genre}
                onChange={setGenre}
                required={true}
                items={[
                    {value: 'M', label: 'Masculino'},
                    {value: 'F', label: 'Feminino'},
                ]}
                optionLabelReference='label'
                optionValueReference='value'
                invalid={invalid_genre}
                setInvalid={setInvalidGenre}
                customStyle={{maxWidth: '360px'}}
            />
            <FormInput 
                mask="99/99/9999"
                type="date"
                label='Data de Nascimento'
                name='birth'
                value={birth}
                onChange={setBirth}
                required={true}
                invalid={invalid_birth}
                setInvalid={setInvalidBirth}
                customStyle={{maxWidth: window.innerWidth > 991 ? '260px' : '100%'}}
            />
            <FormInput 
                mask="999.999.999-99"
                type="text"
                label='CPF'
                name='cpf'
                value={cpf}
                onChange={setCpf}
                required={true}
                invalid={invalid_cpf}
                setInvalid={setInvalidCpf}
                customStyle={{maxWidth: '360px'}}
                disabled
            />
            <FormInput 
                mask="(99) 99999-9999"
                type="text"
                label='Telefone'
                name='phone'
                value={phone}
                onChange={setPhone}
                required={true}
                invalid={invalid_phone}
                setInvalid={setInvalidPhone}
                customStyle={{maxWidth: '360px'}}
            />
            <DefaultBtn
                text="Salvar Alterações"
                onClick={()=> handleUpdateUser()}
                customStyle={{marginTop: window.innerWidth > 991 ? '50px' : '20px', maxWidth: window.innerWidth > 991 ? '165px' : '100%'}}
                backgroundColor="#3B413C"
                textColor="#FFF"
            />
        </>
    );

    const address_container = (
        <>
            <h3 className="sectionTitle">Meus Endereços</h3>
            <div className="row">
                {addresses.map((address: AddressType) => (
                    <div className="col-md-6">
                        <div className="addressCard">
                            <div className="addressCardHeader">
                                <h4>{address.EnderecoResidencialComercial === 'C' ? 'Endereço Comercial' : 'Endereço Residencial'}</h4>
                            </div>
                            <div className="addressCardBody">
                                <p>{`${address.Endereco}, ${address.Numero}`}</p>
                                <p>{`${address.Complemento === '' ? address.Bairro : `${address.Complemento} ${address.Bairro}`}`}</p>
                                <p>{`${address.Cidade} - ${address.Estado}`}</p>
                                <p>{Helper.formatCep(address.CEP)}</p>
                            </div>
                            <div className="addressCardFooter">
                                <TextBtn 
                                    onClick={() => history.push({pathname: 'register-address', state: {address}})}
                                    text="Editar Endereço"
                                    textColor="#FF834F"
                                    customStyle={{textDecoration: 'none', fontWeight: '700', fontSize: window.innerWidth > 991 ? '16px' : '14px'}}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
    
    return (
        <div className="profilePage">
            <div className="defaultPageContent">
                <section className="defaultWhiteSection">
                    <div className="container">
                        <h1 className="defaultSectionTitle">Minha conta</h1>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="tabItens">
                                    <CardSelectItem 
                                        onClick={() => setTabActive('profile')}
                                        title="Dados Pessoais"
                                        subTitle="Ver e alterar dados, e-mail e senha"
                                        selected={tab_active === 'profile'}
                                        customClass="tabContentItems"
                                    />  
                                    <CardSelectItem 
                                        onClick={() => setTabActive('address')}
                                        title="Endereços"
                                        subTitle="Ver e alterar endereços"
                                        selected={tab_active === 'address'}
                                        customClass="tabContentItems"
                                    />  
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="tabContent">
                                    {tab_active === 'profile'
                                        ? profile_container
                                        : address_container
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}