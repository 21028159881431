import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import TextBtn from '../buttons/text-btn';
import Loader from '../loader';
import CartDrawerItem from '../cart-drawer-item';

import './style.scss';
import { Helper } from '../../util';

import { CarrinhoType } from '../../types';
import CartContext from '../../context/cart';

interface CartDrawerProps {
    open: boolean,
    closeAction: Function
}

export default function CartDrawer(props: CartDrawerProps) {
    const { open, closeAction } = props;

    const history = useHistory();

    const { itens, cartTotalPrice } = useContext(CartContext);
    
    const [is_loading, setIsLoading] = useState(false);

    return (
        <>
            <div onClick={() => closeAction()} className={`overlay ${open && "open"}`} />
            <div className={`cartDrawerContainer ${open && "open"}`}>
                <Loader visivel={is_loading} isContentLoader />
                <div className="cartDrawerHeader">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                        <path d="M7 18C5.9 18 5.01 18.9 5.01 20C5.01 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18ZM1 2V4H3L6.6 11.59L5.25 14.04C5.09 14.32 5 14.65 5 15C5 16.1 5.9 17 7 17H19V15H7.42C7.28 15 7.17 14.89 7.17 14.75L7.2 14.63L8.1 13H15.55C16.3 13 16.96 12.59 17.3 11.97L20.88 5.48C20.96 5.34 21 5.17 21 5C21 4.45 20.55 4 20 4H5.21L4.27 2H1ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18Z" fill="black"/>
                    </svg>
                    <h2>Meu Carrinho</h2>
                    <button className="closecartDrawerBtn" onClick={() => closeAction()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#E5E5E5"/>
                            <path d="M16.6667 8.27337L15.7267 7.33337L12 11.06L8.27334 7.33337L7.33334 8.27337L11.06 12L7.33334 15.7267L8.27334 16.6667L12 12.94L15.7267 16.6667L16.6667 15.7267L12.94 12L16.6667 8.27337Z" fill="black"/>
                        </svg>
                    </button>
                </div>
                <div className="cartDrawerBody defaultScrollContainer">
                    {itens.map((item: CarrinhoType) => (
                        <CartDrawerItem 
                            item={item}
                            setLoading={setIsLoading}
                            key={`cartItem-${item.CodigoDoProduto}`}
                        />
                    ))}
                </div>
                <div className="cartDrawerFooter">
                    <div className="defaultTotalsPriceContainer">
                        <div className="spacedContainer">
                            <h3>Total:</h3>
                            <h3><strong>{Helper.formatCurrency(cartTotalPrice())}</strong></h3>
                        </div>
                    </div>
                    <TextBtn 
                        text="Visualizar Carrinho"
                        onClick={() => history.push('/cart-info')}
                    />
                </div>
            </div>
        </>
    )
}