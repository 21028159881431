import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';

import './style.scss';

interface FormInputProps {
  label?: string,
  name?: string,
  value?: string,
  type?: string,
  mask?: string,
  placeholder?: string,
  min?: number,
  maxLength?: number,
  onChange: Function,
  rightBtnAction?: Function,
  rightBtnIcon?: any,
  autoFocus?: boolean,
  required?: boolean,
  disabled?: boolean,
  invalid?: boolean,
  setInvalid?: Function,
  customStyle?: object,
  customInputStyle?: object,
  customLabelStyle?: object,
  leftLabelContent?: any,
}

export default function FormInput(props: FormInputProps){
  const { label, name, value, onChange, placeholder, invalid, setInvalid,
    autoFocus, type, required, disabled, mask, min, maxLength, customLabelStyle,
    rightBtnAction, rightBtnIcon, customStyle, customInputStyle, leftLabelContent } = props;

  const right_btn = (
    <button onClick={() => rightBtnAction ? rightBtnAction() : console.log('entendi nada')} className="rightBtn">
      <img src={rightBtnIcon} alt="Icone Lateral" />
    </button>
  );

  const handleChange = (value: string) => {
    if (setInvalid !== undefined) {
      if (invalid) setInvalid(false);
    }
    onChange(value)
  } 

  return (
    <div style={customStyle} className={`formInputContainer ${invalid ? 'invalid' : ''}`}>
      {mask ? 
        <InputMask 
            mask={mask} 
            name={name}
            value={value} 
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            autoFocus={autoFocus}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            style={customInputStyle}
        />
      : 
        <input 
            type={type}
            name={name}
            value={value} 
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            autoFocus={autoFocus}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            min={min} 
            maxLength={maxLength}
            style={customInputStyle}
        />
    }
    {label && <label style={customLabelStyle} htmlFor={name}>{label}</label>}
    {rightBtnAction && right_btn}
    {leftLabelContent && <div className="leftLabelContent">{leftLabelContent}</div>}
    </div>
  )
} 