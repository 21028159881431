import { ChangeEvent } from 'react';
import './style.scss';

interface SelectInputProps {
  label: string,
  name: string,
  value: string,
  hiddenItem?: string,
  optionValueReference: string,
  optionLabelReference: string,
  onChange: Function,
  items: any[],
  required: boolean,
  disabled?: boolean,
  invalid?: boolean,
  setInvalid?: Function,
  customStyle?: object
}

export default function SelectInput(props: SelectInputProps){
  const { label, name, value, onChange, 
    items, required, disabled, invalid, setInvalid,
    hiddenItem, optionValueReference, customStyle, 
    optionLabelReference } = props;

  const handleChange = (value: string) => {
    if (setInvalid) {
      if (invalid) setInvalid(false);
    }
    onChange(value)
  } 

  return (
    <div style={customStyle} className={`selectInputContainer ${invalid ? 'invalid' : ''}`}>
      <select 
        value={value} 
        name={name} 
        onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.value)} 
        required={required}
        disabled={disabled}
      >
        <option value="" disabled hidden>{hiddenItem}</option>
        {items?.map((item: any, index: number) => <option key={index} value={item[optionValueReference]}>{item[optionLabelReference]}</option>)}
      </select>
      {label && <label htmlFor={name}>{label}</label>}
    </div>
  );
}
