import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { MainApi, ProfileApi } from "../services/api-routes";
import { AddressType, AvisosType, FavoritosType, UserType } from "../types";
import { Helper } from "../util";
import LoaderContext from "./loader";

interface UserContextType {
    user: UserType | null,
    setUser: (user: UserType | null) => void,
    favorites: FavoritosType[],
    setFavorites: (favorite: FavoritosType[]) => void,
    removeFavorite: (id: number) => void,
    delivery_address: AddressType | null,
    setDeliveryAddress: (address: AddressType | null) => void,
    authenticated: boolean,
    setAuthenticated: (auth: boolean) => void
}

const initialValue = {
    user: null,
    setUser: (user: UserType | null) => {},
    favorites: [], 
    setFavorites: (favorite: FavoritosType[]) => [], 
    removeFavorite: (id: number) => {},
    delivery_address: null,
    setDeliveryAddress: (address: AddressType | null) => {},
    authenticated: false,
    setAuthenticated: (auth: boolean) => {}
}

const UserContext = createContext<UserContextType>(initialValue);

export const UserProvider = ({ children }: any) => {
    const [favorites, setFavorites] = useState<FavoritosType[]>([]);
    const [user, setUser] = useState<UserType | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [delivery_address, setDeliveryAddress] = useState<AddressType | null>(null);

    const { setLoading } = useContext(LoaderContext);

    const removeFavorite = (id: number) => {
        const new_list = favorites.filter(item => item.Id !== id);
        setFavorites(new_list);
    } 
    
    const getUser = useCallback(async () => {
        if (!user) {
            const current_user = await MainApi.user.get();
            if (current_user) {
                const user = await MainApi.user.getById(current_user.Id);  
                if (user && user.IdClienteCPFCNPJ !== '07713031812') {
                    setUser(user);
                    setAuthenticated(true);
                    const warnings = await MainApi.warnings.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, '1', '10');
                    if (warnings) warnings.data.forEach((warning: AvisosType) => toast.info(warning.Descricao, {onClose: async () => await MainApi.warnings.delete(warning.Id)}));
                    
                    const addresses = await ProfileApi.address.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, 1, 20);
                    if (addresses && addresses.data.length > 0) setDeliveryAddress(addresses.data[0])
                }
            } else {
                localStorage.clear();
                setAuthenticated(false);
            }
        } else {
            setAuthenticated(true);
            const warnings = await MainApi.warnings.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, '1', '10');
            if (warnings) warnings.data.forEach((warning: AvisosType) => toast.info(warning.Descricao, {onClose: async () => await MainApi.warnings.delete(warning.Id)}));
            
            const addresses = await ProfileApi.address.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, 1, 20);
            if (addresses && addresses.data.length > 0) setDeliveryAddress(addresses.data[0])
        }
    }, [user])

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getUser();
            setLoading(false);
        })();
    }, [getUser, setLoading])

    return (
        <UserContext.Provider value={{ user, setUser, authenticated, setAuthenticated, favorites, setFavorites, removeFavorite, delivery_address, setDeliveryAddress }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserContext;