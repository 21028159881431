import { useEffect, useState, memo, useRef, useContext, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import SearchBar from '../../search-bar';
import DropDownContent from '../../dropdown-content';
import TextBtn from '../../buttons/text-btn';
import CartDrawer from "../../cart-drawer";
import DefaultBtn from '../../buttons/default-btn';
import Navigation from '../../navigation';
import NavigationDrawer from '../../navigation-drawer';

import CartContext from '../../../context/cart';
import LoaderContext from '../../../context/loader';
import UserContext from '../../../context/user';

import { Helper } from '../../../util';
import logoImg from '../../../assets/images/logo.png';
import './style.scss';

import { StorageService } from '../../../services';
import { MainApi } from '../../../services/api-routes';
import { DepartamentosType } from '../../../types';

const MENU_OPTIONS = "@mahishop/menu-options";

interface CustomHeaderProps {
    hasSearch?: boolean,
    hasRightActions?: boolean,
    hasNavigation?: boolean,
}

function CustomHeader(props: CustomHeaderProps){
    const { hasRightActions, hasSearch, hasNavigation } = props;

    const { setLoading } = useContext(LoaderContext);
    const { itens, cartTotalPrice, setItens } = useContext(CartContext);
    const { user, setUser, setFavorites, setDeliveryAddress, authenticated } = useContext(UserContext);

    const history = useHistory();

    const dropDownBtnRef = useRef(null);
    const dropDownContentRef = useRef<HTMLDivElement>(null);

    const [is_dropdown_open, setIsDropDownOpen] = useState(false);
    const [is_navigation_drawer_open, setIsNavigationDrawerOpen] = useState(false);
    const [is_cart_preview_open, setIsCartPreviewOpen] = useState(false);
    const [search_value, setSearchValue] = useState('');
    
    const [options, setOptions] = useState<DepartamentosType[]>([]);

    const getData = useCallback(async () => {
        setLoading(true);
        const options = localStorage.getItem(MENU_OPTIONS);
        if (options) setOptions(JSON.parse(options));
        else if (authenticated && !options) {
            const resp = await MainApi.departamento.index('', 1, 20);
            if (resp) {
                setOptions(resp.data);
                localStorage.setItem(MENU_OPTIONS, JSON.stringify(resp.data));
            }
        } 
        setLoading(false);
    }, [setLoading, authenticated]) 

    useEffect(() => {
        if (hasNavigation) (async () => await getData())();
    }, [getData, hasNavigation])

    useEffect(() => {
        if (hasRightActions) {
            document.addEventListener('click', (event: MouseEvent) => handleCloseOutsideDropDown(event));
    
            return () => document.removeEventListener('click', (event: MouseEvent) => handleCloseOutsideDropDown(event))
        } 
    }, [hasRightActions])

    const handleCloseOutsideDropDown = (event: MouseEvent) => {
        
        if (
            event.target !== dropDownBtnRef?.current && 
            event.target !== dropDownContentRef?.current && 
            event.target !== dropDownContentRef?.current?.childNodes[0] &&
            event.target !== dropDownContentRef?.current?.childNodes[1] &&
            event.target !== dropDownContentRef?.current?.childNodes[1].childNodes[0] &&
            event.target !== dropDownContentRef?.current?.childNodes[1].childNodes[1]
            
        ) setIsDropDownOpen(false);
    }

    const handleLogout = async () => {
        setLoading(true);
        localStorage.clear();
        setUser(null);
        setItens([]);  
        setFavorites([]);  
        setDeliveryAddress(null);
        const token = await MainApi.user.publicAuth(Helper.getFornecedorDocument());
        if (token) {
            StorageService.setToken(token);
            history.push('/');
        }
        setLoading(false);
    }

    const dropdown_content = (
        user !== null
        ? (
            <div className="defaultDropdownContent">
                <button onClick={() => history.push('/personal-info')} className="dropDownItem">Dados Pessoais</button>
                <button onClick={() => history.push('/addresses')} className="dropDownItem">Endereços</button>
                <button onClick={() => history.push('/order')} className="dropDownItem">Meus Pedidos</button>
                <button onClick={() => history.push('/favoritos')} className="dropDownItem">Meus Favoritos</button>
                <button className="dropDownItem" onClick={() => handleLogout()}>Sair</button>
            </div>
        )
        : (
            <div className="defaultDropdownContent">
                <div className="dropDownBoxBody">
                    <h3>Você não está logado</h3>
                    <DefaultBtn 
                        text="Entrar"
                        onClick={() => history.push('/sign-in')}
                        customStyle={{maxWidth: '110px'}}
                        backgroundColor="#3B413C"
                        textColor="#FFF"
                    />
                </div>
                <div className="dropDownBoxFooter alignedContainer">
                    <p>Não possui conta?</p>
                    <TextBtn textColor="rgba(0, 0, 0, .8)" customStyle={{marginLeft: '4px', fontSize: '13px'}} text="Cadastrar" onClick={() => history.push('sign-up')} />
                </div>
            </div>
        )
    )

    const profile_btn = (
        <div ref={dropDownBtnRef} className="profileBtn">
            {user ? 'Minha Conta' : 'Entrar'}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                <path d="M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z" fill="white"/>
            </svg>
        </div>
    )

    const cart_btn = (
        <div className="cartBtn">
            <strong>
                {Helper.sum(itens, 'Quantidade')}
            </strong>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="https://www.w3.org/2000/svg">
                <path d="M6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.59L4.25 12.04C4.09 12.32 4 12.65 4 13C4 14.1 4.9 15 6 15H18V13H6.42C6.28 13 6.17 12.89 6.17 12.75L6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C19.96 3.34 20 3.17 20 3C20 2.45 19.55 2 19 2H4.21L3.27 0H0ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" fill="#FF834F"/>
            </svg>
            {Helper.formatCurrency(cartTotalPrice())}
        </div>
    );
    
    return (
        <div className={`${hasNavigation ? 'customHeaderContainer' : ''}`}>
            <header className="customHeader">
                <CartDrawer 
                    open={is_cart_preview_open}
                    closeAction={() => setIsCartPreviewOpen(false)}
                />
                {window.innerHeight < 991 && (
                    <NavigationDrawer 
                        open={is_navigation_drawer_open}
                        closeAction={() => setIsNavigationDrawerOpen(false)}
                        options={options}
                    />
                )}
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <button onClick={() => history.push('/')}>
                                <img src={logoImg} alt="Logo da marcas" />
                            </button>
                        </div>
                        {hasSearch && (
                            <div className="col-md-5">
                                <div className="searchbarCol">
                                    <SearchBar 
                                        placeholder="O que você está buscando?"
                                        value={search_value}
                                        onChange={setSearchValue}
                                        onSubmit={(event) => {
                                            event.preventDefault();
                                            history.push({pathname: '/category', state: { search_value }})
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {hasRightActions && (
                            <div className="col-md-4">
                                <div data-testid='rightActionContent' className="rightActionBox">
                                    <div className="profileDropContainer">
                                        <TextBtn
                                            onClick={() => setIsDropDownOpen(oldState => !oldState)}
                                            customContent={profile_btn}
                                            textColor="#FFF"
                                            customStyle={{margin: 0, textDecoration: 'none'}}
                                        />
                                        <DropDownContent 
                                            closeAction={() => setIsDropDownOpen(false)} 
                                            title="Meu Perfil" 
                                            content={dropdown_content} 
                                            parentWidth={74}
                                            open={is_dropdown_open}
                                            ref={dropDownContentRef}
                                        />
                                    </div>
                                    <DefaultBtn
                                        onClick={() => setIsCartPreviewOpen(true)}
                                        customContent={cart_btn}
                                        customStyle={{maxWidth: '130px', height: '40px', marginBottom: 0, padding: 0, marginLeft: '24px'}}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="responsiveHeaderContent">
                        <div className={`responsiveHeaderNav${hasSearch ? ' spaceBottom' : ''}`}>
                            <div className="alignedContainer">
                                <button 
                                    className="menuBurgerBtn"
                                    onClick={() => setIsNavigationDrawerOpen(true)}
                                >
                                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="white"/>
                                    </svg>
                                </button>
                                <button className='logoBtn' onClick={() => history.push('/')}>
                                    <img src={logoImg} alt="Logo da marcas" />
                                </button>
                            </div>
                            <div data-testid='rightActionContent' className="rightActionBox">
                                <DefaultBtn
                                    onClick={() => setIsCartPreviewOpen(true)}
                                    customContent={cart_btn}
                                    customStyle={{maxWidth: '130px', height: '40px', marginBottom: 0, padding: 0}}
                                />
                            </div>
                        </div>
                        {hasSearch && 
                            <SearchBar 
                                placeholder="O que você está buscando?"
                                value={search_value}
                                onChange={setSearchValue}
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    history.push({pathname: '/category', state: { search_value }})
                                }}
                            />
                        }
                    </div>
                </div>
            </header>
            {hasNavigation && (
                <Navigation
                    customStyle={{borderBottom: "1px solid #E9E9E9"}}
                    options={options}
                />
            )}
        </div>
    )
} 

export default memo(CustomHeader);