import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import CustomHeader from "../../../components/custom/custom-header";
import NewsletterSection from "../../../components/sections/newsletter-section";
import ProductIndexSection from "../../../components/sections/product-index-section";

import { FavoritosType } from "../../../types";
import UserContext from "../../../context/user";

export default function FavoritesPage() {
  const { favorites } = useContext(UserContext);

  const history = useHistory();

  const [products, setProducts] = useState<FavoritosType[]>([]);
  const getData = useCallback(() => {
    if (favorites) setProducts(favorites);
  }, []) 

  return (
    <div className="categoryPage">
      <div className="defaultPageContent defaultGraySection">
        <ProductIndexSection 
          records={0}
          products={products}
          pageTitle="Meus Favoritos"
          getData={getData}
          onClickProduct={(GTIN: string) => history.push({pathname: '/product', state: { productCode: GTIN, filter_type: 'Favoritos' }})}
          codeReference='CodigoDoProduto'
          descriptionReference='DescricaoResumida'
          valueReference='Valor'
          imagePathReference='PathImg'      
        />
      </div>
    </div>
  );
}