import { deleteReq, getReq, postReq, putReq } from "../../services/api";

const order = {
  index:       async (fornecedor_doc: string, user_document: string, page: number, pageSize: number) => { return await getReq('pedidos', `PedidoVenda?siteFornecedorCNPJ=${fornecedor_doc}&clienteCPFCNPJ=${user_document}&page=${page}&pageSize=${pageSize}`, true) },
  delete:      async (id: number) => { return await deleteReq('pedido', `PedidoVenda/${id}`, true) },
}

const delivery_status = {
  show:      async (fornecedor_doc: string, order_number: number, page: number, pageSize: number) => { return await getReq('status de entrega', `StatusEntrega?siteFornecedorCNPJ=${fornecedor_doc}&numeroPedido=${order_number}&page=${page}&pageSize=${pageSize}`, true) },
}

const order_product = {
  update:      async (body: string) => { return await putReq('pedido', `PedidoVendaProduto`, body, true, false) },
  delete:      async (id: number) => { return await deleteReq('pedido', `PedidoVendaProduto/${id}`, true) },
}

const address = {
  index:       async (fornecedor_doc: string, user_document: string, page: number, pageSize: number) => { return await getReq('endereço de entrega', `EnderecoEntregaCliente?siteFornecedorCNPJ=${fornecedor_doc}&clienteCPFCNPJ=${user_document}&page=${page}&pageSize=${pageSize}`, true) },
  create:      async (body: string) => { return await postReq('endereço de entrega', `EnderecoEntregaCliente`, body, true) },
  update:      async (body: string) => { return await putReq('endereço de entrega', `EnderecoEntregaCliente`, body, true, true) },
  show:        async (id: number) => { return await getReq('endereço de entrega', `EnderecoEntregaCliente/${id}`, true) },
}

const newsletter = {
  subscribe:    async (body: string) => { return await postReq('newsletter', `NewsLetter`, body, true) },
}

const favorite = {
  index:       async (fornecedor_doc: string, user_document: string, page: number, pageSize: number) => { return await getReq('produtos favoritos', `Favorito?siteFornecedorCNPJ=${fornecedor_doc}&clienteCPFCNPJ=${user_document}&page=${page}&pageSize=${pageSize}`, true) },
  create:      async (body: string) => { return await postReq('produto favorito', `Favorito`, body, true) },
  delete:      async (id: number) => { return await deleteReq('usuário', `Favorito/${id}`, true) },
}

export {
  order,
  delivery_status,
  order_product,
  address,
  favorite,
  newsletter
}
