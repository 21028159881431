import { forwardRef } from 'react';
import './style.scss';

interface DropDownContentProps {
    title?: string,
    content?: any,
    closeAction: Function,
    parentWidth?: number,
    open: boolean
}

const DropDownContent = forwardRef<HTMLDivElement, DropDownContentProps>((props, ref) => {
    const { title, open, content, closeAction, parentWidth } = props;

    const style = {
        right: (-(256 / 2) + (parentWidth ? parentWidth / 2 : 50))
    };
    
    return (
        <div
            ref={ref}
            data-testid='dropDownContent' 
            className={`dropDownBox ${open ? "open" : ''}`}
            style={style} 
        >
            <div className="dropDownBoxHeader">
                <h5>{title}</h5>
                <button onClick={() => closeAction()}>
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="https://www.w3.org/2000/svg">
                        <line x1="1.35355" y1="0.646447" x2="13.3536" y2="12.6464" stroke="black"/>
                        <line x1="0.646447" y1="12.6464" x2="12.6464" y2="0.646447" stroke="black"/>
                    </svg>
                </button>
            </div>
            {content}
        </div>
    )
});

export default DropDownContent;