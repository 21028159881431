import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StorageService } from "../services";

import { CartApi, MainApi } from "../services/api-routes";

import { CarrinhoType } from '../types';
import { Helper } from "../util";
import LoaderContext from "./loader";
import UserContext from "./user";

interface CartContextType {
  itens: CarrinhoType[],
  addItem: Function,
  updateItem: Function,
  deleteItem: Function,
  cartTotalPrice: Function,
  setItens: Function,
}

const initialValue = {
  itens: [],
  addItem: (product_code: string, quantity: number, valor: number) => {},
  updateItem: (product_code: string, valor: number, addition: boolean) => {},
  deleteItem: (product_code: string) => {},
  cartTotalPrice: () => {},
  setItens: (cart: CarrinhoType[]) => {},
}

const CartContext = createContext<CartContextType>(initialValue);

export const CartProvider = ({ children }: any) => {
  const [itens, setItens] = useState<CarrinhoType[]>([]);

  const { user } = useContext(UserContext);
  const { setLoading } = useContext(LoaderContext);

  const findItemInStorage = (product_code: string) => {
    const storage_cart = StorageService.getCart();
    if (storage_cart) {
      const storage_item = JSON.parse(storage_cart).find((item: any) => item.product_code === product_code);
      return storage_item;
    } else return null;
  }

  const addItem = async (product_code: string, quantity: number, product_value: number) => {
    if (user) await addItemIfLoggedIn(product_code, quantity, product_value, user.IdClienteCPFCNPJ);
    else await addItemIfNotLoggedIn(product_code, quantity);
  }

  const addItemIfLoggedIn = async (product_code: string, quantity: number, product_value: number, user_document: string) => {
      const item_exists = itens.find((item: CarrinhoType) => item.CodigoDoProduto === product_code);
      const body = {
        Id: 0,
        SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
        ClienteCPFCNPJ: user_document,
        CodigoDoProduto: product_code,
        ValorUnitario: product_value,
        Quantidade: quantity
      }
      if (item_exists) {
        body.Quantidade = quantity - item_exists.Quantidade;
        const cart_item = await CartApi.cart.add(JSON.stringify(body));
        if (cart_item && !cart_item.Message) {
          const new_itens = itens.map((item: CarrinhoType) => {
              return item.CodigoDoProduto === product_code
                  ? cart_item
                  : item
              }
          );
          setItens(new_itens);
          toast.success('Produto adicionado ao carrinho com sucesso!', {toastId: 'addCartSuccess'});
        }
      } else {
        const cart_item = await CartApi.cart.add(JSON.stringify(body));
        if (cart_item && !cart_item.Message) {
          setItens((oldState: CarrinhoType[]) => [ ...oldState, cart_item ]);
          toast.success('Produto adicionado ao carrinho com sucesso!', {toastId: 'addCartSuccess'});
        }
      }
  }

  const addItemIfNotLoggedIn = async (product_code: string, quantity: number) => {
    const storage_cart = StorageService.getCart();
    if (storage_cart) {
      const exists_in_storage = findItemInStorage(product_code);
      if (exists_in_storage) await setExistingItemInStorage(product_code, quantity, storage_cart);
      else await setNewItemInStorage(product_code, quantity);
    } else await setNewItemInStorage(product_code, quantity);
  }

  const setExistingItemInStorage = async (product_code: string, quantity: number, storage_cart: any) => {
    StorageService.setCartItens({product_code, quantity}, true);
    const current_product = await MainApi.produtos.show(product_code);
    if (current_product) {
      const new_itens = itens.map((item: CarrinhoType) => {
          return item.CodigoDoProduto === product_code
              ? {
                Id: storage_cart.length + 1,
                CodigoDoProduto: product_code,
                Descricao: current_product.DescricaoResumida,
                Quantidade: quantity,
                ValorUnitario: current_product.ValorProduto,
                PathImg: current_product.UrlsImagensDoProduto[0].Path
              }
              : item
          }
      );
      setItens(new_itens);
      toast.success('Produto adicionado ao carrinho com sucesso!', {toastId: 'addCartSuccess'});
    }
  }

  const setNewItemInStorage = async (product_code: string, quantity: number) => {
    StorageService.setCartItens({product_code, quantity});
    const current_product = await MainApi.produtos.show(product_code);
    if (current_product && !current_product.Message) {
        const cart_item = {
          Id: 1,
          CodigoDoProduto: product_code,
          Descricao: current_product.DescricaoResumida,
          Quantidade: quantity,
          ValorUnitario: current_product.ValorProduto,
          PathImg: current_product.UrlsImagensDoProduto[0].Path
        }
        setItens((oldState: CarrinhoType[]) => [ ...oldState, cart_item ]);
        toast.success('Produto adicionado ao carrinho com sucesso!', {toastId: 'addCartSuccess'});
    }
  }

  const updateItem = async (product_code: string, product_value: number, is_addition: boolean) => {
    if (user) {
      const body = {
        Id: 0,
        SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
        ClienteCPFCNPJ: user.IdClienteCPFCNPJ,
        CodigoDoProduto: product_code,
        Quantidade: is_addition ? 1 : -1,
        ValorUnitario: product_value
      }
      const cart_item = await CartApi.cart.add(JSON.stringify(body));
      if (cart_item && !cart_item.Message) {
        toast.success('Produto do carrinho atualizado com sucesso!', {toastId: 'updateCartSuccess'});
        const item_exists = itens.find((item: CarrinhoType) => item.CodigoDoProduto === product_code);
        if (item_exists) {
          const new_itens = itens.map((item: CarrinhoType) => {
              return item.CodigoDoProduto === product_code
                  ? cart_item
                  : item
              }
          );
          setItens(new_itens);
        } else toast.error('Erro ao atualizar produto do carrinho!', {toastId: 'updateCartError'});
      } else toast.error('Erro ao atualizar produto do carrinho!', {toastId: 'updateCartError'});
    } else {
      const storage_cart = StorageService.getCart();
      if (storage_cart) {
        const exists_in_storage = findItemInStorage(product_code);
        if (exists_in_storage) {
          if (is_addition) await setExistingItemInStorage(product_code, exists_in_storage.quantity + 1, storage_cart);
          else await setExistingItemInStorage(product_code, exists_in_storage.quantity - 1, storage_cart);
        }
        else await setNewItemInStorage(product_code, 1);
      }
    }
  }

  const deleteItem = async (product_code: string) => {
      const new_itens = itens.filter((item: CarrinhoType) => item.CodigoDoProduto !== product_code);
      setItens(new_itens);
      
      if (user) {
        const item = itens.find((item: CarrinhoType) => item.CodigoDoProduto === product_code);
        if (item) {
          const resp = await CartApi.cart.delete(item.Id);
          if (resp && resp.Message) toast.error('Erro ao excluir produto do carrinho!', {toastId: 'deleteCartError'});
          else toast.success('Produto excluído do carrinho com sucesso!', {toastId: 'deleteCartSuccess'});
        }
      } else {
        const item = findItemInStorage(product_code);
        if (item) {
          StorageService.removeCartItem(product_code);
          toast.success('Produto excluído do carrinho com sucesso!', {toastId: 'deleteCartSuccess'});
        }
      }
  }

  const cartTotalPrice = () => {
    const total_price = itens.reduce((accumulator: number, item: CarrinhoType) => accumulator + (item.ValorUnitario * item.Quantidade || 0), 0);
    return total_price;
  }

  const getData = useCallback(async () => {
    setLoading(true);
    if (user) {
      const cart_record = await CartApi.cart.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, '1', '20');
      if (cart_record && !cart_record.Message) {
        if (itens.length > 0) {
          const cache_itens = itens.map((item) => {
            return {
              Id: 0,
              SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
              ClienteCPFCNPJ: user.IdClienteCPFCNPJ,
              CodigoDoProduto: item.CodigoDoProduto,
              Quantidade: item.Quantidade,
              ValorUnitario: item.ValorUnitario
            }
          })
          
          const resp = await CartApi.cart.addCache(JSON.stringify(cache_itens));
          if (resp) {
            setItens(resp.concat(cart_record.data));
            StorageService.clearCart();
          }
        }
        else setItens(cart_record.data);
      }
    } else {
      const storage_cart = StorageService.getCart();
      if (storage_cart) {
        let storage_item: CarrinhoType[] = [];
        JSON.parse(storage_cart).forEach(async (item: any, index: number) => {
          const current_product = await MainApi.produtos.show(item.product_code);
          if (current_product && !current_product.Message) 
            storage_item.push({
              Id: index + 1,
              CodigoDoProduto: item.product_code,
              Descricao: current_product.DescricaoResumida,
              Quantidade: item.quantity,
              ValorUnitario: current_product.ValorProduto,
              PathImg: current_product.UrlsImagensDoProduto[0].Path
            })
          else StorageService.removeCartItem(item.product_code);
        });
        setItens(storage_item);
      }
    }
    setLoading(false);
  }, [user, setLoading]);

  useEffect(() => {
    (async () => await getData())();
  }, [getData,]);
  
  return (
    <CartContext.Provider value={{ itens, addItem, updateItem, deleteItem, cartTotalPrice, setItens }}>
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;