import { ChangeEvent } from 'react';
import './style.scss';

interface CheckboxInputProps {
    customStyle?: object,
    label?: string,
    name: string,
    value: boolean,
    setValue: Function
}

export default function CheckboxInput(props: CheckboxInputProps){
  const { customStyle, name, label, value, setValue } = props;

  return (
    <div style={customStyle} className="checkboxInputContainer">
        <div className="alignedContainer">
            <input 
                type="checkbox"
                name={name}
                id={name}
                checked={value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setValue()}
            />
            <label htmlFor={name}>{label}</label>
        </div>
    </div>
  );
}
