import { ReactElement } from 'react';
import './style.scss';

interface CardSelectItemProps {
    onClick: Function,
    title: string,
    subTitle?: string,
    selected: boolean,
    icon?: ReactElement,
    customClass?: string,
    actionButtonText?: string
}

export default function CardSelectItem(props: CardSelectItemProps){
    const { onClick, title, subTitle, selected, icon, customClass, actionButtonText } = props;
    
    return (
        <button onClick={() => onClick()} className={`cardSelectItemContainer${selected ? " selected" : ''} ${customClass ? customClass : ''}`}>
            <div className="cardMainInfoContainer">
                {icon}
                <div className="cardSelectItemBody">
                    <h2>{title}</h2>
                    <p>{subTitle}</p>
                </div>
            </div>
            {actionButtonText !== '' && <p className="actionButtonText">{actionButtonText}</p>}
        </button>
    )
} 