import { ChangeEventHandler, useContext, useEffect } from 'react';

import CartContext from '../../../context/cart';
import LoaderContext from '../../../context/loader';
import UserContext from '../../../context/user';
import { CarrinhoType, FavoritosType } from '../../../types';

import BreadCrumps from '../../breadcrumps';
import OutlineBtn from '../../buttons/outline-btn';
import ProductCard from '../../cards/products-card';
import EmptyList from '../../empty-list';
import Loader from '../../loader';

import './style.scss';

interface ProductIndexSectionProps {
    products: any[],
    records: number,
    pageTitle: string,
    filter?: string,
    onFilterChange?: ChangeEventHandler,
    is_loading_more?: boolean,
    getData: Function,
    loadMore?: Function,
    onClickProduct: Function,
    isCompleteProduct?: boolean,
    codeReference: string,
    descriptionReference: string,
    valueReference: string,
    hasImageArray?: boolean,
    imagePathReference: string,
    secondImagePathReference?: string
}

export default function ProductIndexSection(props: ProductIndexSectionProps){
    const { products, records, pageTitle, filter, onFilterChange, 
        is_loading_more, getData, loadMore, onClickProduct } = props;

    const { isCompleteProduct, codeReference, descriptionReference, valueReference, hasImageArray, 
        imagePathReference, secondImagePathReference } = props;

    const { setLoading } = useContext(LoaderContext);
    const { favorites } = useContext(UserContext);
    const { itens } = useContext(CartContext);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (filter) await getData(1, filter);
            else await getData(1);            
            setLoading(false);
        })();

        window.scrollTo(0, 0);
    }, [getData, filter, setLoading]);

    const results_and_filter_content = (
        <div className="spacedContainer filtersContainer">
            <h1 className="defaultSectionTitle">{pageTitle}</h1>
            <div className="alignedContainer">
                <p>Exibindo 1 - {products.length} de {records} resultados</p>
                <div className="sortContainer alignedContainer">
                    <p>Ordenar por:</p>
                    <select 
                        value={filter} 
                        onChange={onFilterChange}
                    >
                        <option value=''></option>
                        <option value='0'>Menor preço</option>
                        <option value='1'>Maior preço</option>
                        <option value='2'>A-Z</option>
                        <option value='3'>Z-A</option>
                    </select>
                </div>
            </div>
        </div>
    )

    return (
        <section className="productIndexSectionContainer">
                <div className="container">
                    <BreadCrumps
                        items={[
                            {pageName: pageTitle, currentPage: true, pagePath: ''},
                        ]}
                    />
                    {(records > 0 && filter !== null) && results_and_filter_content}
                    <div className="row">
                        {products.length > 0
                            ? products.map((product: any) => {
                                const cart_item = itens.find((cart_item: CarrinhoType) => cart_item.CodigoDoProduto === product.CodigoDoProduto);
                                return (
                                    <div key={`product-${product[codeReference]}`} className="col-md-3">
                                        <ProductCard
                                            name={product[descriptionReference]}
                                            price={product[valueReference]}
                                            image={(hasImageArray && secondImagePathReference) ? product[imagePathReference][0][secondImagePathReference] : product[imagePathReference]}
                                            productCode={product[codeReference]}
                                            onClick={() => isCompleteProduct ? onClickProduct(product) : onClickProduct(product[codeReference])}
                                            isFavorite={favorites.length > 0 ? (favorites.find((favorite: FavoritosType) => favorite.CodigoDoProduto === product[codeReference]) ? true : false) : false}
                                            cart_item={cart_item}
                                        />
                                    </div>
                                )
                            })
                            : (<EmptyList
                                title="Ops"
                                subTitle="Nenhum produto encontrado!"
                            />)
                        }
                    </div>
                    <div className="loadMoreContainer">
                        {(loadMore && records > 0 && products.length < records && !is_loading_more) && (
                            <OutlineBtn
                                text="Ver Mais"
                                onClick={() => loadMore()}
                                borderColor="#3B413C"
                                textColor="#3B413C"
                                hoveredTextColor="#FFF"
                            />
                        )}
                        <Loader isLoadMoreContent visivel={is_loading_more ? is_loading_more : false} />
                    </div>
                </div>
        </section>
    );
}