import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import TextBtn from "../../../components/buttons/text-btn";
import DefaultBtn from "../../../components/buttons/default-btn";
import CartProductCard from "../../../components/cards/cart-product-card";
import RadioInput from "../../../components/forms/radio-input";
import FormInput from "../../../components/forms/form-input";
import TextareaInput from "../../../components/forms/textarea-input";
import CardSelectItem from "../../../components/cards/card-select-item";

import LoaderContext from "../../../context/loader";
import CartContext from "../../../context/cart";
import UserContext from "../../../context/user";
import { CarrinhoType, TransportadoraType } from "../../../types";

import './style.scss';
import { Helper } from "../../../util";
import { CartApi } from "../../../services/api-routes";

export default function CartInfoPage(){
  const { itens, cartTotalPrice, setItens } = useContext(CartContext);
  const { setLoading } = useContext(LoaderContext);
  const { user, delivery_address } = useContext(UserContext);

  const history = useHistory();

  const [delivery_type, setDeliveryType] = useState('');
  const [delivery_options, setDeliveryOptions] = useState<TransportadoraType[]>([]);
  const [freight, setFreight] = useState(0);
  const [has_deliver_error, setHasDeliverError] = useState(false);
  const [discount_value, setDiscoutValue] = useState(0);
  const [discount, setDiscount] = useState('');
  
  const [order_observation, setOrderObservation] = useState('');

  const calculateFreight = async (delivery_type: string) => {
      setLoading(true);
      setDeliveryType(delivery_type);
      if (user && delivery_address) {
        let body = {
            SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
            CodigoTransportadora: parseInt(delivery_type),
            CepDestino: delivery_address.CEP,
            ValorDosProduto: cartTotalPrice()
        }
        const resp = await CartApi.freight.calculate(JSON.stringify(body));
        if (resp && !resp.Message) {
            setFreight(resp.ValorDoFrete);
            setHasDeliverError(false);
            toast.success('Frete calculado com sucesso!', {toastId: 'freightSuccess'})
        } else setHasDeliverError(true);
      }
      setLoading(false);
  }

  const checkCouponDiscount = async () => {
        setLoading(true);
        if (user) {
            let body = {
                SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
                ClienteCPFCNPJ: user.IdClienteCPFCNPJ,
                CodigoCupom: discount,
                ValorCompra: cartTotalPrice() + freight
            }
            const resp = await CartApi.coupon.validate(JSON.stringify(body));
            if (resp && !resp.Message) {
                setDiscoutValue(resp.ValorDesconto);
                toast.success('Cupom de desconto adicionado com sucesso!', {toastId: 'discountSuccess'})
            }
        } else history.push('/sign-in');
        setLoading(false);
  }

  const handleFinishPurchases = async () => {
    setLoading(true);
    if (delivery_type === '') toast.error('Selecione o método de entrega!', {toastId: 'freightError'});
    else if (has_deliver_error) toast.error('Endereço selecionado indisponível para entrega!', {toastId: 'deliverError'});
    else if (user) history.push({pathname: '/choose-day', state: { delivery_type, discount_value, freight, order_observation }});
    else if (!user) {
        setLoading(false);
        history.push('/sign-in');
    }
    setLoading(false);
  }

  const handleCleanCart = async () => {
    setLoading(true);
    if (user) await CartApi.cart.deleteAll(user.IdClienteCPFCNPJ);
    setItens([]);
    setLoading(false);
  }

  const getData = useCallback(async () => {
    setLoading(true);
    const resp = await CartApi.carrier.index('', '1', '20');
    if (resp) setDeliveryOptions(resp.data);
    setLoading(false);
  }, [setLoading])
  

  useEffect(() => {
    (async () => await getData())();
    window.scrollTo(0, 0);
  }, []);

  const cart_content = (
    <div className="cartInfosContainer">
        <div className="cartInfosHeader">
            <div className="row">
                <div className="col-md-7">
                    <div>
                        <h3 className="rowTitle">Produto</h3>
                    </div>
                </div>
                <div className="col-md-3">
                    <div>
                        <h3 className="rowTitle">Quantidade</h3>
                    </div>
                </div>
                <div className="col-md-2">
                    <div>
                        <h3 className="rowTitle">Preço</h3>
                    </div>
                </div>
            </div>
            <h3 className="rowTitle responsive">Produtos</h3>
        </div>
        <div className="cartInfosBody">
            <div className="cartInfoProductBox">
                <div className="row">
                    {itens.map((item: CarrinhoType) => (
                        <CartProductCard 
                            item={item}
                            key={`cartInfo-${item.Id}`}
                        />
                    ))}
                </div>
            </div>
                <div className="col-md-6"> 
                    <TextareaInput 
                        name='order_observation'
                        value={order_observation}
                        onChange={setOrderObservation}
                        rows={4}
                        label="Observação"
                    />
                </div>
            <div className="defaultDivider"/>
            <div className="cartResultsBox">
                <RadioInput 
                    name="delivery-type"
                    title="Entrega"
                    options={delivery_options.map((option: TransportadoraType) => {
                        return { value: option.CodigoTransportadora.toString(), label: option.NomeTransportadora }
                    })}
                    currentValue={delivery_type}
                    setCurrentValue={calculateFreight}
                    customStyle={{marginBottom: '24px'}}
                />
                {delivery_address 
                    ? (
                        <div className="col-md-8">
                            <CardSelectItem 
                                onClick={() => history.push({pathname: '/addresses', state: {is_payment_flow: true}})}
                                title={`${delivery_address.Endereco}, ${delivery_address.Numero}`}
                                subTitle={`${delivery_address.Bairro}, ${delivery_address.Cidade}, ${delivery_address.Estado}${delivery_address.Complemento === '' ? '' : ` - ${delivery_address.Complemento}`}`}
                                selected={false}
                                icon={
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="black"/>
                                    </svg>
                                }
                                customClass="selectedAddressBtn"
                                actionButtonText="Alterar Endereço"
                            />
                        </div>
                    )
                    : <TextBtn 
                        text="Adicionar Endereço" 
                        onClick={() => history.push('/register-address')} 
                        customStyle={{marginBottom: '16px'}}
                    />
                }
                <div className="defaultTotalsPriceContainer">
                    <div className="spacedContainer">
                        <p>Subtotal:</p>
                        <p>{Helper.formatCurrency(cartTotalPrice())}</p>
                    </div>
                    <div className="spacedContainer">
                        <p>Desconto:</p>
                        <p>{Helper.formatCurrency(discount_value)}</p>
                    </div>
                    <div className="spacedContainer">
                        <p>Frete:</p>
                        <p>{Helper.formatCurrency(freight)}</p>
                    </div>
                    <div className="spacedContainer">
                        <h3>Total:</h3>
                        <h3><strong>{Helper.formatCurrency(cartTotalPrice() + freight - discount_value)}</strong></h3>
                    </div>
                </div>
                <div className="col-md-4"> 
                    <div className="spacedContainer">
                        <FormInput 
                            type="text"
                            label='Cupom de Desconto:'
                            name='discount'
                            value={discount}
                            onChange={setDiscount}
                        />
                        <TextBtn 
                            text="Consultar"
                            onClick={() => checkCouponDiscount()}
                            customStyle={{marginLeft: '12px', marginTop: '12px'}}
                        />
                    </div>
                </div>
            </div>
            <div className="defaultDivider"/>
        </div>
        <div className="cartInfosFooter">
            <TextBtn 
                onClick={() => history.push('/')}
                text="Continuar Comprando"
            />
            <DefaultBtn 
                onClick={() => handleFinishPurchases()}
                text="Finalizar Compra"
                backgroundColor="#3B413C"
                textColor="#FFF"
                customStyle={{maxWidth: window.innerWidth > 991 ? '252px' : '100%', marginLeft: window.innerWidth > 991 ? '40px' : '0', marginTop: window.innerWidth > 991 ? '0' : '16px'}}
            />
        </div>
    </div>
  )

  const no_items_content = (
      <div className="emptyContainer">
          <h2>Seu carrinho de compras está vazio!</h2>
          <DefaultBtn 
                onClick={() => history.push('/')}
                text="Continuar Comprando"
                backgroundColor="#3B413C"
                textColor="#FFF"
                customStyle={{maxWidth: '252px'}}
          />
      </div>
  )

  return (
    <div className="cartPage">
      <div className="defaultPageContent">
        <section className="defaultWhiteSection">
            <div className="container">
                <div className="spacedContainer mb-4">
                    <h1 className="formsTitle">Meu Carrinho</h1>
                    {itens.length > 0 && (
                        <TextBtn 
                            onClick={() => handleCleanCart()}
                            text="Limpar o carrinho"
                            textColor="#474747"
                        />
                    )}
                </div>
                {itens.length > 0
                    ? cart_content
                    : no_items_content
                }
            </div>
        </section>
      </div>
    </div>
  );
}