export const TOKEN_KEY = "@mahishop-token";
export const EMAIL_KEY = "@mahishop-email";
export const CART_KEY = "@mahishop-cart";
export const MENU_OPTIONS = "@mahishop/menu-options";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(EMAIL_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getCart = () => localStorage.getItem(CART_KEY);

export const setToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

export const setCartItens = (item: any, is_existing_item: boolean = false) => {
  const old_itens = localStorage.getItem(CART_KEY);
  if (old_itens) {
    const itens = JSON.parse(old_itens);
    if (is_existing_item) {
      const new_itens = itens.map((storage_item: any) => {
        return storage_item.product_code === item.product_code
          ? item
          : storage_item
      })
      localStorage.setItem(CART_KEY, JSON.stringify(new_itens));
    } else {
      itens.push(item);
      localStorage.setItem(CART_KEY, JSON.stringify(itens));
    }
  } else localStorage.setItem(CART_KEY, JSON.stringify([item]));
}

export const removeCartItem = (product_code: string) => {
  const old_itens = localStorage.getItem(CART_KEY);
  if (old_itens) {
    const itens = JSON.parse(old_itens);
    const new_itens = itens.filter((item: any) => item.product_code !== product_code);
    localStorage.setItem(CART_KEY, JSON.stringify(new_itens));
  }
}

export const clearCart = () => localStorage.removeItem(CART_KEY);

export const login = (token: string, email: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EMAIL_KEY, email);
};

export const logout = (history: any) => {
  localStorage.clear();
  history.push({pathname: "/"})
};