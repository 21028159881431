import { withRouter } from "react-router-dom";

import { PublicRoute } from "../../util/routes-config";

import HomePage from "./home";
import CategoryPage from "./category";
import ProductPage from "./product";

const AppRoutes = (
  <>
    <PublicRoute exact path="/" component={withRouter(HomePage)} layout="ShopLayout" />
    <PublicRoute exact path="/category" component={withRouter(CategoryPage)} layout="ShopLayout" />
    <PublicRoute exact path="/product" component={withRouter(ProductPage)} layout="ShopLayout" />
  </>
);

export default AppRoutes;