import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FormInput from '../../../components/forms/form-input';
import DefaultBtn from '../../../components/buttons/default-btn';
import TextBtn from '../../../components/buttons/text-btn';
import CustomHeader from '../../../components/custom/custom-header';

import './style.scss'
import { Helper } from '../../../util';

import LoaderContext from '../../../context/loader';
import { AuthApi } from '../../../services/api-routes';
import { toast } from 'react-toastify';

export default function RecoveryPage(){
  const { setLoading } = useContext(LoaderContext);

  const [email, setEmail] = useState('');
  const [invalid_email, setInvalidEmail] = useState(false);

  const history = useHistory();

  const handleRecoveryPassword = async () => {
    setLoading(true);
    if (!verifyParams()) {      
      const resp = await AuthApi.session.recovery_password('{}', email);
      if (resp && !resp.Message) {
        setLoading(false);
        history.push('/sign-in');
        return
      } else setInvalidEmail(true);
    }
    setLoading(false);
  }

  const verifyParams = () => {
    let invalid = false;
    if (email === '' && !Helper.checkEmail(email)) {
      invalid = true;
      setInvalidEmail(true);
      toast.error('Verifique os dados do E-mail!', {toastId: 'emailError'})
    }    
    return invalid;
  }

  return (
    <div className="recoverySection">
      <section className="defaultGraySection fullScreamSizeSection">
          <div className="container">
              <div className="signContainer">
                  <div className="col-md-4">
                      <p className="formsTitle">Digite seu e-mail para receber as próximas instrucões para recuperar sua senha.</p>
                      <FormInput 
                          type="e-mail"
                          label='Seu e-mail'
                          name='E-mail'
                          value={email}
                          onChange={setEmail}
                          required={true}
                          invalid={invalid_email}
                          setInvalid={setInvalidEmail}
                      />
                      <DefaultBtn 
                          text="Recuperar Senha"
                          onClick={() => handleRecoveryPassword()}
                          customStyle={{maxWidth: "100%", marginBottom: '16px'}}
                      />
                      <TextBtn 
                          text="Voltar ao Login"
                          onClick={() => history.push("/sign-in")}
                      />
                  </div>
              </div>
          </div>
      </section>
    </div>
  );
}