import { useHistory } from 'react-router-dom';

import DefaultBtn from '../../../../../components/buttons/default-btn';
import TextBtn from '../../../../../components/buttons/text-btn';
import ReviewProductCard from '../../../../../components/cards/review-product-card';
import { CarrinhoType } from '../../../../../types';

import { Helper } from '../../../../../util';
import './style.scss';

interface ReviewBoxProps {
    title: string,
    itens: CarrinhoType[],
    freight: number,
    discount_value: number,
    total_value: number,
    onClick: Function,
    delivery_date: Date,
    delivery_time: number,
    delivery_type: string
}

export default function ReviewBox(props: ReviewBoxProps) {
    const { title, itens, freight, discount_value, total_value, delivery_date, delivery_time, delivery_type, onClick } = props;
    
    const history = useHistory();

    const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

    return (
        <div className="orderReviewContainer">
            <div className="orderReviewHeader">
                <h2>{title}</h2>
            </div>
            <div className="orderReviewBody">
                {itens.map((item: CarrinhoType) => (
                    <ReviewProductCard
                        key={`cartReview-${item.Id}`}
                        product={item}
                        descriptionReference="Descricao"
                        unitValueReference="ValorUnitario"
                        quantityReference="Quantidade"
                        pathImageReference="PathImg"
                    />
                ))}
                <div className="defaultDivider" />
                <div className="deliveryDateContainer">
                    <h4>Entrega para:</h4>
                    <div className="spacedContainer">
                        <div className="alignedContainer">
                            <div className='dayItem'>
                                <p>{delivery_date?.getDate()}</p>
                                <span>{months[delivery_date?.getMonth()]}</span>
                            </div>
                            <p className="deliveryTime">{delivery_time < 10 ? `0${delivery_time}` : delivery_time}:00 - {delivery_time + 1 < 10 ? `0${delivery_time + 1}` : delivery_time + 1}:00</p>
                        </div>
                        <TextBtn
                            text="Alterar"
                            onClick={() => history.push({pathname: 'choose-day', state: { delivery_type }})}
                            textColor="#2A3A4D"
                        />
                    </div>
                </div>
                <div className="defaultDivider" />
                <div className="defaultTotalsPriceContainer">
                    <div className="spacedContainer">
                        <p>Desconto:</p>
                        <p>{Helper.formatCurrency(discount_value)}</p>
                    </div>
                    <div className="spacedContainer">
                        <p>Subtotal:</p>
                        <p>{Helper.formatCurrency(total_value)}</p>
                    </div>
                    <div className="spacedContainer">
                        <p>Frete:</p>
                        <p>{Helper.formatCurrency(freight)}</p>
                    </div>
                    <div className="spacedContainer">
                        <h3>Total:</h3>
                        <h3><strong>{Helper.formatCurrency(total_value + freight - discount_value)}</strong></h3>
                    </div>
                </div>
                <DefaultBtn
                    text="Finalizar Compra"
                    onClick={onClick}
                    customStyle={{maxWidth: '100%'}}
                    backgroundColor="#3B413C"
                    textColor="#FFF"
                />
            </div>
        </div>
    );
}
