import { ChangeEvent } from 'react';
import './style.scss';

interface OptionType {
    value: string,
    label: string
}

interface RadioInputProps {
    customStyle?: object,
    title?: string,
    name: string,
    options: OptionType[],
    currentValue: string,
    setCurrentValue: Function
}

export default function RadioInput(props: RadioInputProps){
  const { customStyle, name, title, options, currentValue, setCurrentValue } = props;

  const radio_options = (option: OptionType, marked: boolean, index: number) => {
      return (
        <div key={index} className="alignedContainer">
            <input 
                type="radio"
                name={name}
                id={`name-${index + 1}`}
                value={option.value}
                checked={marked}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setCurrentValue(event.target.value)}
            />
            <label htmlFor={`name-${index + 1}`}>{option.label}</label>
        </div>
      )
  }

  return (
    <>
    {title && <h3 className="radioInputTitle">{title}</h3>}
    <div style={customStyle} className="radioInputContainer">
        {options.map((option: OptionType, index: number) => (
            radio_options(option, option.value === currentValue, index)
        ))}
    </div>
    </>
  );
}
