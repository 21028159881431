import { useState, useContext } from 'react';
import { useHistory } from 'react-router';

import LoaderContext from '../../../context/loader';
import UserContext from '../../../context/user';

import { StorageService } from '../../../services';
import { AuthApi, MainApi, ProfileApi } from '../../../services/api-routes';
import { Helper } from '../../../util';

import FormInput from '../../../components/forms/form-input';
import DefaultBtn from '../../../components/buttons/default-btn';
import TextBtn from '../../../components/buttons/text-btn';

import './style.scss';

import visiblePassword from '../../../assets/images/eye.svg';
import invisiblePassword from '../../../assets/images/eye-off.svg';

export default function SignInPage(){
  const { setLoading } = useContext(LoaderContext);
  const { setFavorites, setUser } = useContext(UserContext);

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show_password, setShowPassword] = useState(false);
  const [invalid_email, setInvalidEmail] = useState(false);
  const [invalid_password, setInvalidPassword] = useState(false);

  const login = async () => {
    setLoading(true);
    if (!verifyParams()) {
      
      const body = {
        USERNAME: email,
        PASSWORD: password,
        EMPRESACNPJ: Helper.getFornecedorDocument()
      };
      
      const resp = await AuthApi.session.login(JSON.stringify(body));
      
      if (resp && !resp.Message) {
        StorageService.login(resp, body.USERNAME);
        const user = await MainApi.user.get();
        if (user) {
          const user_detailed = await MainApi.user.getById(user.Id);
          if (user_detailed) {
            setUser(user_detailed);
            const favorites = await ProfileApi.favorite.index(Helper.getFornecedorDocument(), user_detailed.IdClienteCPFCNPJ, 1, 50);
            if (favorites) setFavorites(favorites.data);
            localStorage.removeItem('user_address')
          }
        }
        setLoading(false);
        history.goBack();
        return
      } else {
        setInvalidEmail(true);
        setInvalidPassword(true)
      }
    }
    setLoading(false);
  }

  const verifyParams = () => {
    let invalid = false;
    if ((email === '' && !Helper.checkEmail(email)) && password === '') {
      invalid = true;
      setInvalidPassword(true);
      setInvalidEmail(true);
    } else if (email === '' && !Helper.checkEmail(email)) {
      invalid = true;
      setInvalidEmail(true);
    } else if (password === '') {
      invalid = true;
      setInvalidPassword(true);
    }
    
    return invalid;
  }

  return (
    <div className="signInPage">
      <section className="defaultGraySection fullScreamSizeSection">
          <div className="container">
              <div className="signContainer">
                  <div className="col-md-4">
                      <h1 className="formsTitle">Login do cliente</h1>
                      <FormInput 
                        type="e-mail"
                        label='Seu e-mail'
                        name='E-mail'
                        value={email}
                        onChange={setEmail}
                        required={true}
                        invalid={invalid_email}
                        setInvalid={setInvalidEmail}
                      />
                      <FormInput 
                        type={show_password ? "text" : "password"}
                        label='Sua senha'
                        leftLabelContent={<TextBtn text="Esqueceu a senha?" onClick={() => history.push('recovery')} />}
                        name='password'
                        value={password}
                        onChange={setPassword}
                        required={true}
                        rightBtnAction={() => setShowPassword(!show_password)}
                        rightBtnIcon={show_password ? invisiblePassword : visiblePassword}
                        invalid={invalid_password}
                        setInvalid={setInvalidPassword}
                      />
                      <DefaultBtn 
                          text="Entrar"
                          onClick={() => login()}
                          customStyle={{maxWidth: "100%", marginTop: '32px', marginBottom: "24px"}}
                      />
                  </div>
                  <div className="col-md-6">
                    <p className="authRedirectMessage">Não tem cadastro?<TextBtn customStyle={{marginLeft: '4px'}} text="Cadastrar" onClick={() => history.push('sign-up')} /></p>
                    <div className="defaultDivider" />
                    <p className="privacyPolicyText">Ao continuar com o acesso, você concorda com a nossa <TextBtn textColor="#A4A4A4" text="política de privacidade" onClick={() => console.log('politicas e privacidade')} /></p>
                  </div>
              </div>
          </div>
      </section>
    </div>
  );
}