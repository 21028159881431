import { ChangeEvent, ReactElement, useRef } from 'react';

import FormInput from '../../forms/form-input';

import './style.scss';

interface SecondaryCardSelectItemProps {
    onChange: Function,
    title: string,
    subTitle?: string,
    selected: boolean,
    icon: ReactElement,
    value: any,
    custom_form: ReactElement
}

export default function SecondaryCardSelectItem(props: SecondaryCardSelectItemProps){
    const { value, onChange, title, selected, icon, custom_form } = props;

    const selectRef = useRef<HTMLDivElement>(null);
    
    return (
        <div 
            ref={selectRef} 
            className="secondaryCardSelectItemContainer"
            style={{height: selected ? selectRef?.current?.scrollHeight : 86}} 
        >
            <label htmlFor={`card-select-${value}`} className="secondaryCardSelectItemSelect">
                <input 
                    type="radio"
                    id={`card-select-${value}`}
                    name="card-select"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                    className="checkInput"
                    value={value}
                />
                {icon}
                <p>{title}</p>
            </label>
            {custom_form}
        </div>
    )
} 