import { useCallback, useContext, useEffect, useState } from "react";

import CustomHeader from "../../../components/custom/custom-header";
import NewsletterSection from "../../../components/sections/newsletter-section";
import OrderCard from "./order-components/order-card";
import Pagination from "../../../components/pagination";
import EmptyList from "../../../components/empty-list";

import './style.scss';
import { ProfileApi } from "../../../services/api-routes";
import { PedidosType } from '../../../types';

import LoaderContext from "../../../context/loader";
import UserContext from "../../../context/user";
import { Helper } from "../../../util";

export default function OrderPage() {
  const { setLoading } = useContext(LoaderContext);
  const { user } = useContext(UserContext);

  const [orders, setOrders] = useState<PedidosType[]>([]);
  const [records, setRecords] = useState(0);
  const [page, setPage] = useState(1);

  const setData = async (page: number) => {
    setLoading(true);
    if (user) {
      const resp = await ProfileApi.order.index(Helper.getFornecedorDocument(), user?.IdClienteCPFCNPJ, page, 3);
      if (resp && !resp.Message) {
        setOrders(resp.data);
        setPage(page);
        window.scrollTo(0, 0);
      }
    }
    setLoading(false);
  }

  const getData = useCallback(async (page: number) => {
    setLoading(true);
    if (user) {
      const resp = await ProfileApi.order.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, page, 3);
      if (resp && !resp.Message) {
        setOrders(resp.data);
        setRecords(resp.recordsFiltered);
      }
    }
    setLoading(false);
  }, [user, setLoading])

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => await getData(page))();
  }, [getData]);

  return (
    <div className="orderPage">
      <div className="defaultPageContent">
        <section className="defaultWhiteSection">
          <div className="container">
              <h1 className="defaultSectionTitle">Meus Pedidos</h1>
              {orders.length > 0
                ? orders.map((order: PedidosType) => (
                    <OrderCard 
                        key={`pedido-${order.Id}`}
                        order={order}
                    />
                  ))
                : <EmptyList 
                    title="Continue comprando!"
                    subTitle="Você ainda não tem pedidos feitos!"
                />
              }
              {records > 0 && (
                <Pagination 
                    pageSize={records % 3 === 0 ? records / 3 : (records / 3) + 1}
                    totalItensInPage={3}
                    current_page={page}
                    setData={setData}
                    prevText="Anterior"
                    nextText="Próximo"
                />
              )}
          </div>
        </section>
      </div>
    </div>
  );
}