import './style.scss';

interface PaginationProps {
    pageSize: number,
    totalItensInPage: number,
    current_page: number,
    setData: Function,
    prevText: string,
    nextText: string
}

export default function Pagination(props: PaginationProps){
    const { pageSize, current_page, setData, prevText, nextText } = props;

    return (
        <div className="paginationContainer">
            <button 
                onClick={() => setData(current_page - 1)}
                disabled={current_page === 1}
            >
                {prevText}
            </button>
            {Array(Math.floor(pageSize)).fill(1).map((_: any, index: number) => (
                <button 
                    key={`page-${index}`}
                    onClick={() => setData(index + 1)}
                    className={`${current_page === index + 1 ? 'selected' : ''}`}
                >
                    {index + 1}
                </button>
            ))}
            <button 
                onClick={() => setData(current_page + 1)}
                disabled={current_page === Math.floor(pageSize)}
            >
                {nextText}
            </button>
        </div>
    )
} 