import { useState } from 'react';

import './style.scss';

interface OutlineBtnProps {
    text?: string,
    borderColor?: string,
    textColor?: string,
    hoveredTextColor ?: string,
    onClick: Function,
    customStyle?: object,
    disabled?: boolean,
    disabledBackground?: string
}

export default function OutlineBtn(props: OutlineBtnProps){
    const { text, borderColor, textColor, hoveredTextColor, onClick, customStyle, disabled, disabledBackground } = props;

    const [is_hovered, setIsHovered] = useState(false);
    
    const style = {
        borderColor,
        color: is_hovered ? hoveredTextColor : textColor,
        backgroundColor: (disabled && disabledBackground) ? disabledBackground : (is_hovered ? borderColor : "transparent")
    }
    
    return (
        <button 
            className={`outlineBtn${disabled ? ' disabled' : ''}`}
            style={Object.assign({}, style, customStyle)} 
            onClick={() => onClick()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={disabled}
        >
            {text}
        </button>
    )
} 