import { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import LoaderContext from '../../../context/loader';

import { StorageService } from '../../../services';
import { AuthApi } from '../../../services/api-routes';
import { Helper } from '../../../util';

import FormInput from '../../../components/forms/form-input';
import SelectInput from '../../../components/forms/select-input';
import DefaultBtn from '../../../components/buttons/default-btn';
import CustomHeader from '../../../components/custom/custom-header';
import TextBtn from '../../../components/buttons/text-btn';

import visiblePassword from '../../../assets/images/eye.svg'
import invisiblePassword from '../../../assets/images/eye-off.svg'
import { UserType } from '../../../types';
import UserContext from '../../../context/user';

export default function SignUpPage(){
  const { setLoading } = useContext(LoaderContext);
  const { setUser } = useContext(UserContext);

  const history = useHistory();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [genre, setGenre] = useState('');
  const [birth, setBirth] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [confirm_email, setConfirmEmail] = useState('');
  
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [show_password, setShowPassword] = useState(false);

  const [invalid_name, setInvalidName] = useState(false);
  const [invalid_lastName, setInvalidLastName] = useState(false);
  const [invalid_genre, setInvalidGenre] = useState(false);
  const [invalid_birth, setInvalidBirth] = useState(false);
  const [invalid_email, setInvalidEmail] = useState(false);
  const [invalid_confirm_email, setInvalidConfirmEmail] = useState(false);
  const [invalid_phone, setInvalidPhone] = useState(false);
  const [invalid_cpf, setInvalidCpf] = useState(false);
  const [invalid_password, setInvalidPassword] = useState(false);
  const [invalid_confirm_password, setInvalidConfirmPassword] = useState(false);

  const register = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (verifyParams()) {
      const birth_date = birth.split('/');
      const body = {
        Id: 0,
        SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
        IdClienteCPFCNPJ: cpf.replace(/[^\d]+/g,''),
        NomeCliente: `${name} ${lastName}`,
        DataNascimento: `${birth_date[1]}/${birth_date[0]}/${birth_date[2]}`,
        Sexo: genre,
        TelefoneCelular: Helper.removePhoneMask(phone),
        Email: email,
        EmailValidado: false,
        Ativo: true,
        Login: email,
        Senha: password,
        CodePasswordRecovery: 0,
        DateExpirationCode: ''
      };
      const resp = await AuthApi.session.register(JSON.stringify(body));
      if (resp && !resp.Message) {
        await login(email, password, resp);
        setLoading(false);
        history.push({pathname: '/register-address', state: { is_continue_register_action: true }});
        return
      } else if (resp && resp.ValidationErrors) {
          if (resp.ValidationErrors.DocumentoIdClienteCPFCNPJ) {
            toast.error(resp.ValidationErrors.DocumentoIdClienteCPFCNPJ);
            setInvalidCpf(true);
          }
      }
    }
    setLoading(false);
  }

  const login = async (email: string, password: string, user: UserType) => {
    const body = {
      USERNAME: email,
      PASSWORD: password,
      EMPRESACNPJ: Helper.getFornecedorDocument()
    };
    
    const resp = await AuthApi.session.login(JSON.stringify(body));
    
    if (resp && !resp.Message) {
        StorageService.login(resp, body.USERNAME);
        setUser(user);
    }
  }

  const verifyParams = () => {
      let invalid;

      if (name === '') {
        setInvalidName(true);
        invalid = true;
        toast.error('Nome é obrigatório!', {toastId: 'nameError'});
      }
      if (lastName === '') {
        setInvalidLastName(true);
        invalid = true;
        toast.error('Sobrenome é obrigatório!', {toastId: 'lastNameError'});
      }
      if (genre === '') {
        setInvalidGenre(true);
        invalid = true;
        toast.error('Gênero é obrigatório!', {toastId: 'genreError'});
      }
      if (birth === '') {
        setInvalidBirth(true);
        invalid = true;
        toast.error('Data de Nascimento é obrigatório!', {toastId: 'birthError'});
      }
      if (phone === '') {
        setInvalidPhone(true);
        invalid = true;
        toast.error('Telefone é obrigatório!', {toastId: 'phoneError'});
      } else if (!Helper.checkPhone(Helper.removePhoneMask(phone))) {
        setInvalidPhone(true);
        invalid = true;
        toast.error('Telefone não é válido!', {toastId: 'phoneInvalidError'});
      } 
      if (email === '') {
        setInvalidEmail(true);
        invalid = true;
        toast.error('E-mail é obrigatório!', {toastId: 'emailError'});
      } else if (!Helper.checkEmail(email)) {
        setInvalidEmail(true);
        invalid = true;
        toast.error('E-mail não é válido!', {toastId: 'emailInvalidError'});
      }
      if (email !== '' && email !== confirm_email) {
        setInvalidConfirmEmail(true);
        invalid = true;
        toast.error('Confirmação de e-mail não corresponde!', {toastId: 'confirmEmailError'});
      }
      if (cpf === '') {
        setInvalidCpf(true);
        invalid = true;
        toast.error('CPF é obrigatório!', {toastId: 'cpfError'});
      } else if (!Helper.checkCpf(cpf)) {
        setInvalidCpf(true);
        invalid = true;
        toast.error('CPF não é válido!', {toastId: 'cpfInvalidError'});
      }
      if (password === '') {
        setInvalidPassword(true);
        invalid = true;
        toast.error('Senha é obrigatória!', {toastId: 'passwordError'});
      } else if (password !== confirm_password) {
        setInvalidConfirmPassword(true);
        invalid = true;
        toast.error('Confirmação de senha não corresponde!', {toastId: 'confirmPasswordError'});
      }
      return !invalid;
  }

  return (
    <>
      <section className="defaultGraySection">
        <div className="container">
            <div className="signContainer">
                <div className="col-md-4">
                  <h1 className="formsTitle">Cadastro</h1>
                  <p className="authRedirectMessage">Já é cadastrado?<TextBtn customStyle={{marginLeft: '4px'}} text="Entrar" onClick={() => history.push('sign-in')} /></p>
                  <FormInput 
                    type="text"
                    label='Nome'
                    name='name'
                    value={name}
                    onChange={setName}
                    required={true}
                    invalid={invalid_name}
                    setInvalid={setInvalidName}
                  />
                  <FormInput 
                    type="text"
                    label='Sobrenome'
                    name='lastName'
                    value={lastName}
                    onChange={setLastName}
                    required={true}
                    invalid={invalid_lastName}
                    setInvalid={setInvalidLastName}
                  />
                  <SelectInput 
                    label='Gênero'
                    name='genre'
                    value={genre}
                    onChange={setGenre}
                    required={true}
                    items={[
                      {value: 'M', label: 'Masculino'},
                      {value: 'F', label: 'Feminino'},
                    ]}
                    optionLabelReference='label'
                    optionValueReference='value'
                    invalid={invalid_genre}
                    setInvalid={setInvalidGenre}
                  />
                  <FormInput 
                    mask="(99) 99999-9999"
                    type="text"
                    label='Telefone'
                    name='phone'
                    value={phone}
                    onChange={setPhone}
                    required={true}
                    invalid={invalid_phone}
                    setInvalid={setInvalidPhone}
                  />
                  <FormInput 
                    mask="99/99/9999"
                    type="date"
                    label='Data de Nascimento'
                    name='birth'
                    value={birth}
                    onChange={setBirth}
                    required={true}
                    invalid={invalid_birth}
                    setInvalid={setInvalidBirth}
                  />
                  <FormInput 
                    mask="999.999.999-99"
                    type="text"
                    label='CPF'
                    name='cpf'
                    value={cpf}
                    onChange={setCpf}
                    required={true}
                    invalid={invalid_cpf}
                    setInvalid={setInvalidCpf}
                  />
                  <FormInput 
                    type="e-mail"
                    label='E-mail'
                    name='email'
                    value={email}
                    onChange={setEmail}
                    required={true}
                    invalid={invalid_email}
                    setInvalid={setInvalidEmail}
                  />
                  <FormInput 
                    type="e-mail"
                    label='Confirmar e-mail'
                    name='confirm_email'
                    value={confirm_email}
                    onChange={setConfirmEmail}
                    required={true}
                    invalid={invalid_confirm_email}
                    setInvalid={setInvalidConfirmEmail}
                  />
                  <FormInput 
                    type={show_password ? "text" : "password"}
                    label='Crie sua Senha'
                    name='password'
                    value={password}
                    onChange={setPassword}
                    required={true}
                    rightBtnAction={() => setShowPassword(!show_password)}
                    rightBtnIcon={show_password ? invisiblePassword : visiblePassword}
                    invalid={invalid_password}
                    setInvalid={setInvalidPassword}
                  />
                  <FormInput 
                    type="password"
                    label='Confirme sua Senha'
                    name='password'
                    value={confirm_password}
                    onChange={setConfirmPassword}
                    required={true}
                    invalid={invalid_confirm_password}
                    setInvalid={setInvalidConfirmPassword}
                  />
                  <DefaultBtn 
                      text="Continuar"
                      onClick={()=> register()}
                      customStyle={{maxWidth: "100%", marginTop: '32px'}}
                  />
                  <p className="privacyPolicyText">Ao continuar com o acesso, você concorda com a nossa <TextBtn textColor="#A4A4A4" text="política de privacidade" onClick={() => history.push('/privacy-policy')} /></p>
                </div>
            </div>
        </div>
      </section>
    </>
  );
}