import { ChangeEvent } from 'react';
import './style.scss';

interface TextareaInputProps {
    customStyle?: object,
    label?: string,
    name: string,
    value: string,
    onChange: Function,
    rows: number
}

export default function TextareaInput(props: TextareaInputProps){
  const { customStyle, name, label, value, onChange, rows } = props;

  return (
    <div style={customStyle} className="textareaInputContainer">
        <textarea 
            name={name}
            value={value}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)}
            rows={rows}
        />
        <label htmlFor={name}>{label}</label>
    </div>
  );
}
