import { Route, Redirect } from "react-router-dom";
import Layout from "../components/layout";
import { StorageService } from "../services";

export const PublicRoute = ({ component: Component, layout, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={(props: any) => (
          <Layout layout={layout}>
            <Component {...props}/>
          </Layout>
        )
      }
    />
  );
}

export const AuthRoute = ({ component: Component, layout, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={(props: any) =>
        (StorageService.isAuthenticated()) ? (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        ) : (
          <Layout layout={layout}>
            <Component {...props}/>
          </Layout>
        )
      }
    />
  );
}

export const PrivateRoute = ({ component: Component, layout, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={(props: any) =>
        (StorageService.isAuthenticated()) ? (
          <Layout layout={layout}>
            <Component {...props}/>
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/sign-in", state: { from: props.location } }} />
        )
      }
    />
  );
}