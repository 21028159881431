import { useHistory } from "react-router-dom";

import DefaultBtn from '../../buttons/default-btn';
import './style.scss';

interface CategoriesCardProps {
    name: string,
    image: any,
    path: object
}

export default function CategoriesCard(props: CategoriesCardProps){
    const { name, image, path } = props;

    const history = useHistory();
    
    return (
        <div className="categoriesCardContainer">
            <img src={image} alt={`Imagem da categoria ${name}`} />
            <h3>{name}</h3>
            <DefaultBtn
                onClick={() => history.push(path)} 
                text="Ver"
                customStyle={{maxWidth: "110px"}}
            />
        </div>
    )
} 