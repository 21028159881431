import './style.scss';

interface LoaderProps {
  visivel: boolean,
  isContentLoader?: boolean,
  isLoadMoreContent?: boolean
}

export default function Loader(props: LoaderProps) {
  const { visivel, isContentLoader, isLoadMoreContent } = props;

  const loader = (
    <div className={`loaderBackground${isContentLoader ? ' contentLoaderBg' : ''}${isLoadMoreContent ? ' loadMoreContent' : ''}`}>
      <div className='loader'>
        <div></div>
      </div>
    </div>
  );

  return visivel ? loader : null;
}
