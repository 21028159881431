import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import LoaderContext from '../../../context/loader';
import { ProfileApi } from '../../../services/api-routes';
import { Helper } from '../../../util';

import DefaultBtn from '../../buttons/default-btn';
import FormInput from '../../forms/form-input';

import './style.scss';


export default function NewsletterSection() {
    const { setLoading } = useContext(LoaderContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [invalid_name, setInvalidName] = useState(false);
    const [invalid_email, setInvalidEmail] = useState(false);

    const handleSubscribe = async () => {
      setLoading(true);
      if (!verifyParams()) {        
        const body = {
            Id: 0,
            nome: name,
            email: email
        };
        
        const resp = await ProfileApi.newsletter.subscribe(JSON.stringify(body));
        
        if (resp && !resp.Message) {
            setName('');
            setEmail('');
            toast.success('Newsletter assinada com sucesso!', {toastId: 'newsletterSuccess'})
        }
      }
      setLoading(false);
    }
  
    const verifyParams = () => {
      let invalid = false;
      if ((email === '' && !Helper.checkEmail(email)) && name === '') {
        invalid = true;
        setInvalidName(true);
        setInvalidEmail(true);
        toast.error('Verifique os dados!', {toastId: 'verifyNewsError'})
      } else if (email === '' && !Helper.checkEmail(email)) {
        invalid = true;
        setInvalidEmail(true);
        toast.error('Verifique se o e-mail está correto!', {toastId: 'emailNewsError'})
      } else if (name === '') {
        invalid = true;
        setInvalidName(true);
        toast.error('Nome é obrigatório!', {toastId: 'nameNewsError'})
      }      
      return invalid;
    }

    return (
        <section className="newsletterSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="newsletterItemContainer">
                            <h3>Fique por dentro das novidades</h3>
                            <p>Assine nossa newsletter</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="newsletterItemContainer">
                            <FormInput 
                                label="Nome"
                                placeholder="Seu nome"
                                customLabelStyle={{color: "#FFF", fontSize: '12px'}}
                                name="nome"
                                value={name}
                                onChange={setName}
                                customStyle={{marginBottom: 0}}
                                invalid={invalid_name}
                                setInvalid={setInvalidName}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="newsletterItemContainer">
                            <FormInput 
                                label="E-mail"
                                placeholder="Seu e-mail"
                                customLabelStyle={{color: "#FFF", fontSize: '12px'}}
                                name="email"
                                value={email}
                                onChange={setEmail}
                                customStyle={{marginBottom: 0}}
                                invalid={invalid_email}
                                setInvalid={setInvalidEmail}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="newsletterItemContainer">
                            <DefaultBtn 
                                text="Enviar"
                                onClick={() => handleSubscribe()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}