import { withRouter } from "react-router-dom";

import { PublicRoute, PrivateRoute } from "../../util/routes-config";

import RegisterAddressPage from "./register-address";
import AddressesPage from "./addresses";
import PersonalInfoPage from "./personal-info";
import OrderPage from "./order";
import FavoritesPage from "./favorites";

const AuthRoutes = (
  <>
    <PrivateRoute exact path="/register-address" component={withRouter(RegisterAddressPage)} layout="BlankLayout" />
    <PrivateRoute exact path="/addresses" component={withRouter(AddressesPage)} layout="BlankLayout" />
    <PrivateRoute exact path="/personal-info" component={withRouter(PersonalInfoPage)} layout="ShopLayout" />
    <PrivateRoute exact path="/order" component={withRouter(OrderPage)} layout="ShopLayout" />
    <PrivateRoute exact path="/favoritos" component={withRouter(FavoritesPage)} layout="ShopLayout" />
  </>
);

export default AuthRoutes;