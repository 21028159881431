import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CartContext from '../../context/cart';
import { CarrinhoType } from '../../types';

import { Helper } from '../../util';
import './style.scss';

import RangeInput from '../forms/range-input';

interface CartDrawerItemProps {
    item: CarrinhoType,
    setLoading: Function
}

export default function CartDrawerItem(props: CartDrawerItemProps){
    const { item, setLoading } = props;

    const history = useHistory();

    const { addItem, updateItem, deleteItem } = useContext(CartContext);

    const [product_quantity, setProductQuantity] = useState(item.Quantidade);

    useEffect(() => {
        setProductQuantity(item.Quantidade);
    }, [item.Quantidade])

    const addFunction = async () => {
        setLoading(true);
        setProductQuantity(oldState => oldState + 1);
        await updateItem(item.CodigoDoProduto, item.ValorUnitario, true);
        setLoading(false)
    }

    const removeFunction = async () => {
        setLoading(true);
        if (product_quantity > 1) {
            setProductQuantity(oldState => oldState - 1);
            await updateItem(item.CodigoDoProduto, item.ValorUnitario, false);
        } else await deleteItem(item.CodigoDoProduto);
        setLoading(false)
    }

    const deleteFunction = async () => {
        setLoading(true);
        await deleteItem(item.CodigoDoProduto);
        setLoading(false)
    }

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        setProductQuantity(parseInt(event.target.value));
        if (parseInt(event.target.value) > 0) await addItem(item.CodigoDoProduto, parseInt(event.target.value), item.ValorUnitario);
        else await deleteItem(item.CodigoDoProduto);
        setLoading(false)
    }
    
    return (
        <div className="cartDrawerProduct">
            <div className='cartDrawerProductWrapper'>
                <div className="defaultThumbnail">
                    <img src={item.PathImg} alt="Imagem do produto" />
                </div>
                <div className="cartDrawerProductInfos">
                    <div className="alignedContainer">
                        <button 
                            className="productName" 
                            onClick={() => history.push({pathname: '/product', state: {filter_type: 'Departamento', productCode: item.CodigoDoProduto}})}
                        >
                            {Helper.formatBigString(item.Descricao)}
                        </button>
                        <button onClick={deleteFunction}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
                                <path d="M3.99998 12.6667C3.99998 13.4 4.59998 14 5.33331 14H10.6666C11.4 14 12 13.4 12 12.6667V4.66667H3.99998V12.6667ZM12.6666 2.66667H10.3333L9.66665 2H6.33331L5.66665 2.66667H3.33331V4H12.6666V2.66667Z" fill="#EB001B"/>
                            </svg>
                        </button>
                    </div>
                    <div className="alignedContainer">
                        <RangeInput
                            addFunction={addFunction}
                            removeFunction={removeFunction}
                            onChange={onChange}
                            value={product_quantity}
                            buttonWidth={24}
                            fontSize={13}
                            customStyle={{maxWidth: '91px', height: "24px", marginRight: "10px"}}
                        />
                        <span>{Helper.formatCurrency(item.ValorUnitario * item.Quantidade)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
} 