import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './style.scss';

import { Helper } from "../../../util";
import { ProfileApi } from '../../../services/api-routes';

import { CarrinhoType, FavoritosType } from '../../../types';
import UserContext from '../../../context/user';
import CartContext from '../../../context/cart';

import DefaultBtn from '../../buttons/default-btn';
import RangeInput from '../../forms/range-input';
import Loader from '../../loader';

interface ProductCardProps {
    productCode: string,
    image?: any,
    name: string,
    description?: string,
    price: number,
    isFavorite?: boolean,
    quantity?: number,
    onClick: Function,
    cart_item: CarrinhoType | undefined,
    is_slide_responsive_item?: true
}

export default function ProductCard(props: ProductCardProps){
    const { name, image, price, isFavorite, cart_item, productCode, onClick, is_slide_responsive_item } = props;

    const { setFavorites, removeFavorite, user, favorites } = useContext(UserContext);
    const { addItem, updateItem, deleteItem } = useContext(CartContext);

    const history = useHistory();

    const [is_favorite, setIsFavorite] = useState(isFavorite);
    const [is_loading, setIsLoading] = useState(false);
    const [disable_range, setDisableRange] = useState(false);
    const [product_quantity, setProductQuantity] = useState(cart_item?.Quantidade || 0);

    useEffect(() => {
        if (cart_item?.Quantidade) setProductQuantity(cart_item?.Quantidade);
    }, [cart_item?.Quantidade])

    const handleFavorite = async () => {
        if (user) {
            const is_favorite = favorites.find((favorite: FavoritosType) => favorite.CodigoDoProduto === productCode);
            
            if (!is_favorite) {
                let body = {
                    "Id": 0,
                    "SiteFornecedorCNPJ": Helper.getFornecedorDocument(),
                    "ClienteCPFCNPJ": user?.IdClienteCPFCNPJ,
                    "CodigoDoProduto": productCode
                }
        
                const resp = await ProfileApi.favorite.create(JSON.stringify(body));
                if (resp) {
                    let new_favs = favorites;
                    new_favs.push(resp);
                    setFavorites(new_favs);
                    setIsFavorite(true);
                }
            } else {
                await ProfileApi.favorite.delete(is_favorite.Id);
                removeFavorite(is_favorite.Id);
                setIsFavorite(false);
            }
        } else history.push('/sign-in');
    }

    const handleAddProductToCart = async () => {
        setIsLoading(true);
        let new_quantity = await addItem(productCode, 1, price);
        if (new_quantity) setProductQuantity(new_quantity);
        setIsLoading(false);
    }

    const addFunction = async () => {
        setDisableRange(true);
        setProductQuantity(oldState => oldState + 1);
        await updateItem(productCode, price, true);
        setDisableRange(false)
    }

    const removeFunction = async () => {
        setDisableRange(true);
        if (product_quantity > 1) {
            setProductQuantity(oldState => oldState - 1);
            await updateItem(productCode, price, false);
        } else {
            await deleteItem(productCode);
            setProductQuantity(0);
        }
        setDisableRange(false)
    }

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setDisableRange(true);
        setProductQuantity(parseInt(event.target.value));
        if (parseInt(event.target.value) > 0) await addItem(productCode, parseInt(event.target.value), price);
        else await deleteItem(productCode);        
        setDisableRange(false)
    }
    
    return (
        <div className={`productCardContainer${is_slide_responsive_item ? ' responsiveItem': ''}`}>
            <Loader visivel={is_loading} isContentLoader />
            <button className="favoriteBtn" onClick={() => handleFavorite()}>
                {!is_favorite 
                    ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                        <path d="M16.5 3C14.76 3 13.09 3.81 12 5.09C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.42 2 8.5C2 12.28 5.4 15.36 10.55 20.04L12 21.35L13.45 20.03C18.6 15.36 22 12.28 22 8.5C22 5.42 19.58 3 16.5 3ZM12.1 18.55L12 18.65L11.9 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 5.99 11.07 7.36H12.94C13.46 5.99 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55Z" fill="black"/>
                    </svg>
                    : <svg width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#E43232" d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />
                    </svg>                                              
                }
            </button>
            <button onClick={() => onClick()}>
                <div className="productCardHeader">
                    <img src={image} alt={`Imagem do produto ${name}`} />
                </div>
                <div className="productCardBody">
                    <p>{name.length > 50 ? `${name.slice(0, 50)}...` : name}</p>
                    <h2>{Helper.formatCurrency(price)}</h2>
                </div>
            </button>
            <div className="productCardFooter">
                {product_quantity > 0
                    ? (
                        <RangeInput
                            addFunction={addFunction}
                            removeFunction={removeFunction}
                            onChange={onChange}
                            value={product_quantity}
                            customStyle={{maxWidth: '100%'}}
                            disabled={disable_range}
                        />
                    )
                    : (
                        <DefaultBtn
                            text="Adicionar"
                            onClick={() => handleAddProductToCart()}
                            customStyle={{ maxWidth: "100%"}}
                        />
                    )
                }
            </div>
        </div>
    )
} 