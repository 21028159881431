import { ChangeEvent, useContext, useState } from 'react';

import RangeInput from '../../forms/range-input';

import './style.scss';

import { Helper } from "../../../util";

import LoaderContext from '../../../context/loader';
import CartContext from '../../../context/cart';
import { CarrinhoType } from '../../../types';

interface CartProductCardProps {
    item: CarrinhoType
}

export default function CartProductCard(props: CartProductCardProps){
    const { item } = props;

    const { addItem, updateItem, deleteItem } = useContext(CartContext);
    const { setLoading } = useContext(LoaderContext);

    const [product_quantity, setProductQuantity] = useState(1);
    const [disable, setDisable] = useState(false);

    const addFunction = async () => {
        setLoading(true);
        setProductQuantity(oldState => oldState + 1);
        await updateItem(item.CodigoDoProduto, item.ValorUnitario, true);
        setLoading(false)
    }

    const removeFunction = async () => {
        setLoading(true);
        if (product_quantity > 1) {
            setProductQuantity(oldState => oldState - 1);
            await updateItem(item.CodigoDoProduto, item.ValorUnitario, false);
        } else await deleteItem(item.CodigoDoProduto);
        setLoading(false)
    }

    const deleteFunction = async () => {
        setLoading(true);
        await deleteItem(item.CodigoDoProduto);
        setLoading(false)
    }

    const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setDisable(true);
        setProductQuantity(parseInt(event.target.value));
        if (parseInt(event.target.value) > 0) await addItem(item.CodigoDoProduto, parseInt(event.target.value), item.ValorUnitario);
        else await deleteItem(item.CodigoDoProduto);
        setDisable(false)
    }
    
    return (
        <>
            <div className="col-md-7">
                <div className="alignedContainer cartProductCardContainer">
                    <div className="defaultThumbnail">
                        <img src={item.PathImg} alt="Imagem do produto" />
                    </div>
                    <div className="cartProductDetail">
                        <h4>{item.Descricao}</h4>
                        <p>GTIN: {item.CodigoDoProduto}</p>
                    </div>
                    <button onClick={deleteFunction} className="responsiveRemoveItemBtn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M5.99988 19C5.99988 20.1 6.89988 21 7.99988 21H15.9999C17.0999 21 17.9999 20.1 17.9999 19V7H5.99988V19ZM7.99988 9H15.9999V19H7.99988V9ZM15.4999 4L14.4999 3H9.49988L8.49988 4H4.99988V6H18.9999V4H15.4999Z" fill="black"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="col-md-3">
                <div className='cartProductCardContainer'>
                    <div className="justifyRow">
                        <RangeInput 
                            value={item.Quantidade}
                            addFunction={addFunction}
                            removeFunction={removeFunction}
                            onChange={onChange}
                            disabled={disable}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-2">
                <div className='cartProductCardContainer'>
                    <div className="spacedContainer justifyRow">
                        <p className="priceText">{Helper.formatCurrency(item.ValorUnitario * item.Quantidade)}</p>
                        <button onClick={deleteFunction}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
                                <path d="M5.99988 19C5.99988 20.1 6.89988 21 7.99988 21H15.9999C17.0999 21 17.9999 20.1 17.9999 19V7H5.99988V19ZM7.99988 9H15.9999V19H7.99988V9ZM15.4999 4L14.4999 3H9.49988L8.49988 4H4.99988V6H18.9999V4H15.4999Z" fill="black"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="responsiveJustifyRow">
                <RangeInput 
                    value={item.Quantidade}
                    addFunction={addFunction}
                    removeFunction={removeFunction}
                    onChange={onChange}
                    disabled={disable}
                />
                <p className="priceText">{Helper.formatCurrency(item.ValorUnitario * item.Quantidade)}</p>
            </div>
        </>
    )
} 