import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import LoaderContext from "../../../context/loader";
import CartContext from "../../../context/cart";
import UserContext from "../../../context/user";
import { CardsType, CondicaoPagamentoType, TipoPagamentoType } from "../../../types";

import { Helper } from "../../../util";
import { CartApi } from "../../../services/api-routes";

import TextBtn from "../../../components/buttons/text-btn";
import RadioInput from "../../../components/forms/radio-input";
import FormInput from "../../../components/forms/form-input";
import CheckboxInput from "../../../components/forms/checkbox-input";
import OutlineBtn from "../../../components/buttons/outline-btn";
import SelectInput from "../../../components/forms/select-input";
import ReviewBox from "./payment-method-components/review-box";
import CardsList from "./payment-method-components/cards-list";

import './style.scss';

export default function PaymentMethodPage(){
    const { setLoading } = useContext(LoaderContext);
    const { itens, cartTotalPrice } = useContext(CartContext);
    const { user, delivery_address } = useContext(UserContext);

    const history = useHistory();
    const location = useLocation();

    const [payment_types, setPaymentTypes] = useState<TipoPagamentoType[]>([]);
    const [payment_type_selected, setPaymentTypeSelected] = useState('');
    const [payment_conditions, setPaymentConditions] = useState<CondicaoPagamentoType[]>([]);
    const [freight, setFreight] = useState(0);

    const [parcels, setParcels] = useState('');
    const [holder_name, setHolderName] = useState('');
    const [holder_document, setHolderDocument] = useState('');
    const [card_number, setCardNumber] = useState('');
    const [card_due, setCardDue] = useState('');
    const [card_sedurity_code, setCardSedurityCode] = useState('');

    const [invalid_holder_name, setInvalidHolderName] = useState(false);
    const [invalid_holder_document, setInvalidHolderDocument] = useState(false);
    const [invalid_card_number, setInvalidCardNumber] = useState(false);
    const [invalid_card_due, setInvalidCardDue] = useState(false);
    const [invalid_card_sedurity_code, setInvalidCardSedurityCode] = useState(false);

    const [save_card, setSaveCard] = useState(false);
    const [user_cards, setUserCards] = useState<CardsType[]>([]);
    const [add_new_card, setAddNewCard] = useState(false);
    const [selected_card, setSelectedCard] = useState<number | null>(null);

    const [delivery_date, setDeliveryDate] = useState<Date>(new Date());
    const [delivery_time, setDeliveryTime] = useState(0);
    const [delivery_type, setDeliveryType] = useState('');
    const [discount_value, setDiscoutValue] = useState(0);

    const handleSelectPaymentType = async (type: string) => {
        setLoading(true);
        setPaymentTypeSelected(type);
        const resp = await CartApi.payment_types.show(type);
        if (resp) {
            setPaymentConditions(resp.CondicaoPagamento);
            setParcels(resp.CondicaoPagamento[0].Id);
        }
        setLoading(false);
    }

    const handleEndPayment = async () => {
        setLoading(true);
        const order = await addOrder();
        if (order)
        //console.log('teste', user_cards.length > 0 && !add_new_card)
        if (user_cards.length > 0 && !add_new_card) await purchaseWithExistingCard(order);
        else await purchaseWithNewCard(order);
        setLoading(false);
    };

    const addOrder = async () => {
        const { order_observation } = location.state;

        const date_prevision = new Date(delivery_date.getFullYear(), delivery_date.getMonth() + 1, delivery_date.getDate(), delivery_time, 0, 0);
        const order_products = itens.map(item => {
            return {
                Id: 0,
                CodigoDoProduto: item.CodigoDoProduto,
                Quantidade: item.Quantidade,
                ValorUnitario: item.ValorUnitario,
                ValorTotalProduto: item.ValorUnitario * item.Quantidade,
                ValorFrete: freight,
                ValorDesconto: discount_value,
                ValorTotal: item.ValorUnitario * item.Quantidade
            }
        });

        let body = {
            Id: 0,
            SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
            ClienteCPFCNPJ: user?.IdClienteCPFCNPJ,
            NumeroPedido: 0,
            DataNota: null,
            NumeroNota: 0,
            ValorPedido: cartTotalPrice(),
            ValorDesconto: discount_value,
            ValorFrete: freight,
            ValorTotal: cartTotalPrice() + freight - discount_value,
            IdTipoPagamento: payment_type_selected,
            IdCondicaoPagamento: parcels,
            IdTransportadora: delivery_type,
            IdEnderecoEntrega: delivery_address?.Id,
            DataPedido: new Date(),
            DataPrevisãoEntrega: date_prevision,
            Observacoes: order_observation,
            Produtos: order_products
        }
        const resp = await CartApi.order.add(JSON.stringify(body))
        if (resp) return resp;
        else return;
    }

    const purchaseWithExistingCard = async (order: any) => {
        if (selected_card) {
            if (card_sedurity_code === '') toast.error('Informe o código de segurança do cartão!')
            else {    
                const { freight } = location.state;
                const card = user_cards.find((card: CardsType) => card.Id === selected_card);
                let parcels_number = payment_conditions.find((item: CondicaoPagamentoType) => item.Id === parseInt(parcels));
                if (card && parcels_number) {
                    const body = {
                        EmpresaCNPJ: Helper.getFornecedorDocument(),
                        ClienteCPFCNPJ: user?.IdClienteCPFCNPJ,
                        TipoOperacao: 'Credito',
                        NumeroCartao: card.NumeroCartao,
                        CodigoSeguranca: card_sedurity_code,
                        MesExpiracao: card.MesExpiracao,
                        AnoExpiracao: card.AnoExpiracao,
                        NomeTitularCartao: card.NomeTitularCartao,
                        TitularCPFCNPJ: card.ClienteCPFCNPJ,
                        IdPedido: order.Id,
                        NossoDocumento: order.NumeroPedido,
                        Valor: cartTotalPrice() + freight - discount_value,
                        NumeroDeParcelas: parcels_number.NumeroDeParcela,
                        CadastrarCartao: false
                    };
                    const resp = await CartApi.payment.createCobranca(JSON.stringify(body));
                    if (resp && !resp.Message) history.push({pathname: '/payment-done', state: {payment_status: resp, order}});
                } else toast.error('Cartão não encontrado!')
            }
        } else toast.error('Selecione o método de pagamento!');
    }

    const purchaseWithNewCard = async (order: any) => {
        if (verifyParams()) {
            let expiration_date = card_due.split('/');
            let parcels_number = payment_conditions.find((item: CondicaoPagamentoType) => item.CodigoCondicaoPagamento === parseInt(parcels));
            if (parcels_number) {
                const body = {
                    EmpresaCNPJ: Helper.getFornecedorDocument(),
                    ClienteCPFCNPJ: user?.IdClienteCPFCNPJ,
                    TipoOperacao: 'Credito',
                    NumeroCartao: card_number.replaceAll(' ', ''),
                    CodigoSeguranca: card_sedurity_code,
                    MesExpiracao: expiration_date[0],
                    AnoExpiracao: expiration_date[1],
                    NomeTitularCartao: holder_name,
                    TitularCPFCNPJ: holder_document.replace(/[^\d]+/g,''),
                    IdPedido: order.Id,
                    NossoDocumento: order.NumeroPedido,
                    Valor: cartTotalPrice() + freight - discount_value,
                    NumeroDeParcelas: parcels_number.NumeroDeParcela,
                    CadastrarCartao: save_card
                };
                const resp = await CartApi.payment.createCobranca(JSON.stringify(body));
                if (resp && !resp.Message) {
                    //console.log('cobranca_resp', resp)
                    history.push({pathname: '/payment-done', state: {payment_status: resp, order}});
                }
            } else toast.error('Erro com dados do pagamento!', {toastId: 'parcelsError'});
        }
    }

    const verifyParams = () => {
        let invalid;
        if (
            holder_name === '' ||
            holder_document === '' ||
            card_number === '' ||
            card_due === '' ||
            card_sedurity_code === ''
        ) {
          invalid = true;
          setInvalidHolderName(holder_name === '');
          setInvalidHolderDocument(holder_document === '');
          setInvalidCardNumber(card_number === '');
          setInvalidCardDue(card_due === '');
          setInvalidCardSedurityCode(card_sedurity_code === '');
          toast.error('Verifique os dados do cartão!', {toastId: 'addressError'})
        }
        return !invalid;
    }


    const getData = useCallback(async () => {
        setLoading(true);        
        const { delivery_type, discount_value, freight, selected_day, selected_time } = location.state;
        setDeliveryDate(selected_day);
        setDeliveryTime(selected_time);
        setDeliveryType(delivery_type);
        setDiscoutValue(discount_value || 0);
        setFreight(freight || 0);
        if (user?.CartoesCliente) setUserCards(user?.CartoesCliente);

        const resp = await CartApi.payment_types.index('', '1', '20');
        if (resp) setPaymentTypes(resp.data);
        
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        (async () => await getData())();
        window.scrollTo(0, 0)
    }, [getData]);

    const register_card_form = (
        <div className="col-md-8">
            <div className="col-md-8">   
                <SelectInput 
                    label='Nº de Parcelas'
                    name='parcels'
                    value={parcels}
                    onChange={setParcels}
                    required={true}
                    items={payment_conditions.map((condition: CondicaoPagamentoType) => {
                        return {value: condition.Id, label: condition.DescricaoCondicaoPagamento}
                    })}
                    optionLabelReference='label'
                    optionValueReference='value'
                />
            </div>
            <FormInput
                mask="9999 9999 9999 9999"
                type="text"
                label='Número'
                name='card_number'
                value={card_number}
                onChange={setCardNumber}
                required={true}
                invalid={invalid_card_number}
                setInvalid={setInvalidCardNumber}
            />
            <div className="row">
                <div className="col-md-8">
                    <FormInput
                        mask="99/99"
                        type="text"
                        label='Vencimento'
                        name='card_due'
                        value={card_due}
                        onChange={setCardDue}
                        required={true}
                        invalid={invalid_card_due}
                        setInvalid={setInvalidCardDue}
                    />
                </div>
                <div className="col-md-4">
                    <FormInput
                        type="text"
                        label='Código de Segurança'
                        name='card_sedurity_code'
                        value={card_sedurity_code}
                        onChange={setCardSedurityCode}
                        required={true}
                        invalid={invalid_card_sedurity_code}
                        setInvalid={setInvalidCardSedurityCode}
                    />
                </div>
            </div>
            <FormInput
                type="text"
                label='Nome do Titular'
                name='holder_name'
                value={holder_name}
                onChange={setHolderName}
                required={true}
                invalid={invalid_holder_name}
                setInvalid={setInvalidHolderName}
            />
            <FormInput
                type="text"
                mask="999.999.999-99"
                label='Documento do Titular'
                name='holder_document'
                value={holder_document}
                onChange={setHolderDocument}
                required={true}
                invalid={invalid_holder_document}
                setInvalid={setInvalidHolderDocument}
            />
            <CheckboxInput
                name="saveCardCheckbox" 
                label="Salvar Cartão"
                value={save_card}
                setValue={() => setSaveCard((oldState: boolean) => !oldState)}
                customStyle={{marginBottom: '24px'}}
            />
            {user_cards.length > 0 && (
                <OutlineBtn 
                    text="Voltar"
                    onClick={() => setAddNewCard(false)}
                    textColor="#3B413C"
                    borderColor="#3B413C"
                    customStyle={{maxWidth: '98px'}}
                />
            )}
        </div>
    )

    const card_form = (
        <div>
            <div className='col-md-6'>
                <SelectInput 
                    label='Nº de Parcelas'
                    name='parcels'
                    value={parcels}
                    onChange={setParcels}
                    required={true}
                    items={payment_conditions.map((condition: CondicaoPagamentoType) => {
                        return {value: condition.CodigoCondicaoPagamento, label: condition.DescricaoCondicaoPagamento}
                    })}
                    optionLabelReference='label'
                    optionValueReference='value'
                />
            </div>
            <div className='col-md-4'>
                <FormInput 
                    type="text"
                    label='Código de Segurança'
                    name='security_code'
                    value={card_sedurity_code}
                    onChange={setCardSedurityCode}
                    required={true}
                />
            </div>
        </div>
    )

    return (
        <div className="paymentMethodPage">
            <section className="defaultGraySection fullScreamSizeSection">
                <div className="container">
                    <div className="spacedContainer paymentHeader">
                        <h1 className="formsTitle">Pagamento</h1>
                        <TextBtn text="Voltar" onClick={() => history.goBack()} />
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="paymentMethodContainer">
                                <RadioInput
                                    name="payment-method-type"
                                    options={payment_types.map((type: TipoPagamentoType) => {
                                        return {value: type.Id.toString(), label: type.DescricaoTipoPagamento}
                                    })}
                                    currentValue={payment_type_selected}
                                    setCurrentValue={handleSelectPaymentType}
                                    title='Método de Pagamento'
                                    customStyle={{marginBottom: '24px'}}
                                />
                                {payment_type_selected === '1' && 
                                    (user_cards.length === 0 || add_new_card
                                        ? register_card_form
                                        : <CardsList 
                                            user_cards={user_cards}
                                            selected_card={selected_card}
                                            setSelectedCard={setSelectedCard}
                                            setCardSedurityCode={setCardSedurityCode}
                                            setAddNewCard={setAddNewCard}
                                            custom_form={card_form}
                                        />
                                    )
                                }
                                {payment_type_selected === '2' && (
                                    <div />
                                )}
                                {payment_type_selected === '3' && (
                                    <div className="col-md-6">   
                                        <SelectInput 
                                            label='Nº de Parcelas'
                                            name='parcels'
                                            value={parcels}
                                            onChange={setParcels}
                                            required={true}
                                            items={payment_conditions.map((condition: CondicaoPagamentoType) => {
                                                return {value: condition.CodigoCondicaoPagamento, label: condition.DescricaoCondicaoPagamento}
                                            })}
                                            optionLabelReference='label'
                                            optionValueReference='value'
                                        />
                                    </div>
                                )}
                                <p className="privacyPolicyText">Lembramos que o valor total da sua compra pode ser alterado para menos. Caso isso ocorra, haverá a atualização do valor conforme o cupom fiscal. É importante ressaltar que essa atualização é feita pelo banco e, por isso, pode demorar alguns dias.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <ReviewBox 
                                title="Resumo do Pedido"
                                itens={itens}
                                freight={freight}
                                discount_value={discount_value}
                                total_value={cartTotalPrice()}
                                onClick={handleEndPayment}
                                delivery_type={delivery_type}
                                delivery_date={delivery_date}
                                delivery_time={delivery_time}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}