import moment from 'moment';
import { PedidosStatusType } from '../../../../../types';
import './style.scss';

interface OrderStatusProps {
    status_approved: PedidosStatusType | undefined,
    status_packing: PedidosStatusType | undefined,
    status_waiting_transport: PedidosStatusType | undefined,
    status_in_transport: PedidosStatusType | undefined,
    status_delivered: PedidosStatusType | undefined,
    active_status: number
}

export default function OrderStatus(props: OrderStatusProps){
    const { status_approved, status_packing, status_waiting_transport, status_in_transport, status_delivered, active_status } = props;
    
    return (
        <div className="orderStatusContainer">
            <div className="orderStatusProgress">
                {Array(5).fill(null).map((_: any, index: number) => (
                    <span className={`${index + 1 === active_status ? 'active' : ''}`}/>
                ))}
            </div>  
            <div className="statusContainer">
                <div className="orderStageContainer">
                    <p>{status_approved ? status_approved.Descricao : 'Pedido Aprovado'}</p>
                    <h4>{status_approved ? moment(status_approved.DataStatus).format('DD/MM/YYYY') : '00/00/0000'}</h4>
                </div>
                <div className="orderStageContainer">
                    <p>{status_packing ? status_packing.Descricao : 'Pedido em Separação'}</p>
                    <h4>{status_packing ? moment(status_packing.DataStatus).format('DD/MM/YYYY') : '00/00/0000'}</h4>
                </div>
                <div className="orderStageContainer">
                    <p>{status_waiting_transport ? status_waiting_transport.Descricao : 'Aguardando Transporte'}</p>
                    <h4>{status_waiting_transport ? moment(status_waiting_transport.DataStatus).format('DD/MM/YYYY') : '00/00/0000'}</h4>
                </div>
                <div className="orderStageContainer">
                    <p>{status_in_transport ? status_in_transport.Descricao : 'Em Transporte'}</p>
                    <h4>{status_in_transport ? moment(status_in_transport.DataStatus).format('DD/MM/YYYY') : '00/00/0000'}</h4>
                </div>
                <div className="orderStageContainer">
                    <p>{status_delivered ? status_delivered.Descricao : 'Pedido Entregue'}</p>
                    <h4>{status_delivered ? moment(status_delivered.DataStatus).format('DD/MM/YYYY') : '00/00/0000'}</h4>
                </div>
            </div>
        </div>
    )
} 