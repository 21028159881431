import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';

import TextBtn from '../../../components/buttons/text-btn';
import CardSelectItem from '../../../components/cards/card-select-item';
import OutlineBtn from '../../../components/buttons/outline-btn';
import DefaultBtn from '../../../components/buttons/default-btn';

import { AddressType } from '../../../types';
import UserContext from '../../../context/user';
import LoaderContext from '../../../context/loader';

import './style.scss';
import { MainApi, ProfileApi } from '../../../services/api-routes';
import { Helper } from '../../../util';

export default function AddressesPage(){
  const { setLoading } = useContext(LoaderContext);
  const { user, delivery_address, setDeliveryAddress } = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();

  const [addresses, setAddresses] = useState<AddressType[]>([]);
  const [selected_address, setSelectedAddress] = useState<AddressType>();

  const [is_payment_flow, setIsPaymentFlow] = useState(false);

  const handleSelectAddress = async (data: AddressType) => {
    setLoading(true);
    if (selected_address && selected_address.Id !== data.Id) {
        setSelectedAddress(data);
        setDeliveryAddress(data);
        const resp = await MainApi.baseCep.get(data.CEP);
        if (resp && resp.Message) toast.error('O endereço selecionado não é válido para entregas');
    } 
    setLoading(false);
  }

  const getData = useCallback(async () => {
        if (user) {
            if (location.state) {
                const { is_payment_flow } = location.state;
                if (is_payment_flow) setIsPaymentFlow(is_payment_flow);
            }
            const resp = await ProfileApi.address.index(Helper.getFornecedorDocument(), user.IdClienteCPFCNPJ, 1, 20);
            if (resp) {
                if (resp.data.length > 0) {
                    setAddresses(resp.data);
                    if (delivery_address) setSelectedAddress(delivery_address);
                    else setSelectedAddress(resp.data[0]);
                } else history.push({pathname: '/register-address', state: {address: null}});
            }
        }        
  }, [user])

  useEffect(() => {
      (async () => {
          setLoading(true);
          getData();
          setLoading(false);
      })();
  }, [getData, setLoading]);   

  return (
    <div className="addressesPage">
        <section className="defaultGraySection fullScreamSizeSection">
            <div className="container">
                <div className="signContainer">
                    <div className="col-md-8">
                        <div className="spacedContainer">
                            <h1 className="formsTitle">Endereços de Entrega</h1>
                            <TextBtn text="Voltar" onClick={() => history.goBack()} />
                        </div>
                        {addresses?.map((address: AddressType) => (
                            <CardSelectItem
                                title={`${address.Endereco}, ${address.Numero}`}
                                key={address.Id}
                                subTitle={`${address.Bairro}, ${address.Cidade}, ${address.Estado}${address.Complemento === '' ? '' : ` - ${address.Complemento}`}`}
                                onClick={() => handleSelectAddress(address)}
                                selected={selected_address?.Id === address.Id}
                                icon={
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="black"/>
                                    </svg>
                                }
                            />
                        ))}
                        <div className="alignRightContainer">
                            <OutlineBtn 
                                text="Novo Endereço"
                                onClick={() => is_payment_flow ? history.push({pathname: '/register-address', state: { is_payment_flow: true }}) : history.push('/register-address')}
                            />
                            {is_payment_flow && (
                                <DefaultBtn
                                    text="Continuar"
                                    onClick={() => history.push('/cart-info')}
                                    customStyle={window.innerWidth > 991 ? {marginLeft: '16px'} : {marginTop: '16px'}}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}