import { ChangeEvent, useCallback, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import CustomHeader from "../../../components/custom/custom-header";
import NewsletterSection from "../../../components/sections/newsletter-section";
import ProductIndexSection from "../../../components/sections/product-index-section";

import "./style.scss";

import { BannerType, DepartamentosType, MarcasType, ProdutosType } from "../../../types";
import LoaderContext from "../../../context/loader";

import { MainApi } from "../../../services/api-routes";

export default function CategoryPage() {
  const { setLoading } = useContext(LoaderContext);

  const history = useHistory();
  const location = useLocation();

  const [filter, setFilter] = useState('');
  const [title, setTitle] = useState<string>('');
  const [records, setRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [filter_type, setFilterType] = useState('');
  const [products, setProducts] = useState<ProdutosType[]>([]);
  const [is_loading_more, setIsLoadingMore] = useState(false);

  const setData = (data: ProdutosType[], title: string, page: number) => {
    setTitle(title);
    setPage(page);
    if (page === 1) setProducts(data);
    else setProducts(oldState => oldState.concat(data));
  }

  const setDepartments = async (category: DepartamentosType, filter: string, page: number) => {
    const resp = await MainApi.produtos.getByDepartment(category.Id.toString(), filter, page, 20);
    if (resp) {
      setData(resp.data, category.DescricaoDepartamento, page);
      setRecords(resp.recordsFiltered);
    }
    setFilterType('Departamento');
  }

  const setBrands = async (brand: MarcasType, filter: string, page: number) => {
    const resp = await MainApi.produtos.getByBrands(brand.Id.toString(), filter, page, 20);
    if (resp) {
      setData(resp.data, brand.DescricaoMarca, page);
      setRecords(resp.recordsFiltered);
    }
    setFilterType('Marca');
  }

  const setSearchItens = async (search_value: string, filter: string, page: number) => {
    const resp = await MainApi.produtos.index(search_value, filter, page, 20);
    if (resp) {
      setData(resp.data, `Resultados de: ${search_value}`, page);
      setRecords(resp.recordsFiltered);
    }
    setFilterType(search_value);
  }

  const setBannerItens = async (bannerInfo: BannerType, filter: string, page: number) => {
    let resp;
    if (bannerInfo.TipoDestaque === 'Departamento') resp = await MainApi.produtos.getByDepartment(bannerInfo.Codigo, filter, page, 20);
    else if (bannerInfo.TipoDestaque === 'Marca') resp = await MainApi.produtos.getByBrands(bannerInfo.Codigo, filter, page, 20);
    if (resp) {
      setData(resp.data, bannerInfo.DescricaoResumida, page);
      setRecords(resp.recordsFiltered);
    }
  }

  const getData = useCallback(async (page: number, filter: string) => {
    const { category, brand, bannerInfo, search_value } = location.state;

    if (category) await setDepartments(category, filter, page);
    else if (brand) await setBrands(brand, filter, page);
    else if (search_value) await setSearchItens(search_value, filter, page);
    else if (bannerInfo) await setBannerItens(bannerInfo, filter, page);

  }, [location]) 

  const loadMore = async () => {
    setIsLoadingMore(true);
    await getData(page + 1, filter);
    setIsLoadingMore(false);
  }

  const onFilterChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);
    setFilter(event.target.value);
    await getData(1, event.target.value);
    setLoading(false);
  }

  return (
    <div className="categoryPage" >
      <div className="defaultPageContent defaultGraySection">
        <ProductIndexSection
          products={products}
          records={records}
          pageTitle={title}
          is_loading_more={is_loading_more}
          filter={filter}
          onFilterChange={onFilterChange}
          getData={getData}
          loadMore={loadMore}
          isCompleteProduct
          onClickProduct={(product: any) => history.push({pathname: '/product', state: { product, filter_type }})}
          codeReference='CodigoDoProduto'
          descriptionReference='DescricaoResumida'
          valueReference='ValorProduto'
          hasImageArray
          imagePathReference='UrlsImagensDoProduto'  
          secondImagePathReference="Path"    
        />
      </div>
    </div>
  );
}