import { withRouter } from "react-router-dom";

import { PublicRoute, PrivateRoute } from "../../util/routes-config";

import ChooseDayPage from "./choose-day";
import PaymentMethodPage from "./payment-method";
import PaymentDonePage from "./payment-done";
import CartInfoPage from "./cart-info";

const AppRoutes = (
  <>
    <PublicRoute exact path="/cart-info" component={withRouter(CartInfoPage)} layout="ShopLayout" />
    <PrivateRoute exact path="/choose-day" component={withRouter(ChooseDayPage)} layout="BlankLayout" />
    <PrivateRoute exact path="/payment-method" component={withRouter(PaymentMethodPage)} layout="BlankLayout" />
    <PrivateRoute exact path="/payment-done" component={withRouter(PaymentDonePage)} layout="BlankLayout" />
  </>
);

export default AppRoutes;