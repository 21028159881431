import { deleteReq, getReq, postReq } from "../../services/api";

const cart = {
  index:       async (fornecedor_doc: string, user_document: string, page: string, pageSize: string) => { return await getReq('carrinhos de compras', `CarrinhoCompras?siteFornecedorCNPJ=${fornecedor_doc}&clienteCPFCNPJ=${user_document}&page=${page}&pageSize=${pageSize}`, true) },
  add:         async (body: string) => { return await postReq('carrinhos de compras', `CarrinhoCompras`, body, true) },
  addCache:    async (body: string) => { return await postReq('carrinhos de compras', `CarrinhoCompras/PostAll`, body, true) },
  delete:      async (id: number) => { return await deleteReq('item do carrinho', `CarrinhoCompras/${id}`, true) },
  deleteAll:   async (user_document: string) => { return await deleteReq('carrinhos de compras', `CarrinhoCompras?clienteCPFCNPJ=${user_document}`, true) },
}

const coupon = {
  validate:   async (body: string) => { return await postReq('cupom de desconto', `CupomDesconto`, body, true) },
}

const freight = {
  calculate:  async (body: string) => { return await postReq('calculo de frete', `CalcularFrete`, body, true) },
}

const payment = {
  createCobranca:     async (body: string) => { return await postReq('cobrança de cartão', `Cobranca/CobrancaCartao`, body, true) },
  getOperacao:        async (body: string) => { return await postReq('cobrança de cartão', `Cobranca/ConsultaOperacao`, body, true) },
}

const payment_condition = {
  show:     async (id: string) => { return await getReq('carrinhos de compras', `iCondicaoPagamento/GetCondicaoPagamento/${id}`, true) },
}

const payment_types = {
  show:     async (id: string) => { return await getReq('tipo de pagamento', `TipoPagamento/GetTipoPagamento/${id}`, true) },
  index:    async (term: string, page: string, pageSize: string) => { return await getReq('tipos de pagamento', `TipoPagamento?term=${term}&page=${page}&pageSize=${pageSize}`, true) },
}

const carrier = {
  index:       async (term: string, page: string, pageSize: string) => { return await getReq('transportadoras', `Transportadora?term=${term}&page=${page}&pageSize=${pageSize}`, true) },
  show:        async (id: number) => { return await getReq('transportadora', `Transportadora/${id}`, true) },
}

const order = {
  add:    async (body: string) => { return await postReq('adicionar pedido', `PedidoVenda`, body, true) },
}

const schedule = {
  add:                async (body: string) => { return await postReq('agendamento de entrega', `AgendaEntregaVenda`, body, true) }, 
  getAvailability:    async (body: string) => { return await postReq('horarios disponiveis', `AgendaEntregaVenda/HorariosDisponiveis`, body, true) },
}

export {
  payment,
  payment_types,
  payment_condition,
  cart,
  coupon,
  freight,
  carrier,
  order,
  schedule
}