import './style.scss';

interface TextBtnProps {
    text?: string,
    textColor?: string,
    onClick: Function,
    customStyle?: object,
    customContent?: any
}

export default function TextBtn(props: TextBtnProps){
    const { text, textColor, customStyle, customContent, onClick } = props;
    
    const style = {
        color: textColor,
    }
    
    return (
        <button 
            data-testid="textBtn"
            className="textBtn" 
            style={Object.assign({}, style, customStyle)} 
            onClick={() => onClick()}
        >
            {customContent ? customContent : text}
        </button>
    )
} 