import { AuthRoute } from "../../util/routes-config";
import { withRouter } from "react-router-dom";

import SignInPage from "./sign-in";
import SignUpPage from "./sign-up";
import RecoveryPage from "./recovery";

const AuthRoutes = (
  <>
    <AuthRoute exact path="/sign-in" component={withRouter(SignInPage)} layout="BlankLayout" />
    <AuthRoute exact path="/sign-up" component={withRouter(SignUpPage)} layout="BlankLayout" />
    <AuthRoute exact path="/recovery" component={withRouter(RecoveryPage)} layout="BlankLayout" />
  </>
);

export default AuthRoutes;