import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation} from "react-router";

import CustomHeader from "../../../components/custom/custom-header";
import DefaultBtn from "../../../components/buttons/default-btn";

import './style.scss';
import { Helper } from "../../../util";

import productImg from '../../../assets/images/product-img.png';
import cardLogo from '../../../assets/images/MasterCard.png';

import CartContext from "../../../context/cart";
import { ProfileApi } from "../../../services/api-routes";
import { AddressType } from "../../../types";

export default function PaymentDonePage(){
    const { setItens } = useContext(CartContext);
    
    const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

    const history = useHistory();
    const location = useLocation();

    const [payment_type_reference, setPaymentTypeReference] = useState<any | null>(null);
    const [order, setOrder] = useState<any | null>(null);
    const [delivery_address, setDeliveryAddress] = useState<AddressType | null>(null);

    const getData = useCallback(async () => {
        const { payment_status, order } = location.state;
        setPaymentTypeReference(payment_status.Ultimos4NumerosDoCartao);
        setOrder(order);
        await getDeliveryAddress(order.IdEnderecoEntrega)
    }, [])

    const getDeliveryAddress = async (address_id: number) => {
        const address = await ProfileApi.address.show(address_id);
        if (address) setDeliveryAddress(address);
    }

    useEffect(() => {
        (async () => await getData())();        
    }, [getData])

    // console.log('payment_type_reference', payment_type_reference)
    // console.log('order', order)
    // console.log('delivery_address', delivery_address)

    const greetings_message_section = (
        <div className="spacedContainer">
            <div className="col-md-4">
                <div className="thanksMessageContainer">
                    <h1>Muito obrigado!</h1>
                    <p>Enviamos uma confirmação da compra para o e-mail cadastrado na conta.</p>
                </div>
            </div>
            <div className="col-md-3">
                <DefaultBtn 
                    text="Voltar para Home"
                    onClick={() => {      
                        setItens([]);
                        history.push('');
                    }}
                    customStyle={{maxWidth: '100%', marginTop: '16px'}}
                    backgroundColor="#3B413C"
                    textColor="#FFF"
                />
            </div>
        </div>
    )

    const payment_types_info = (
        <div>
            <h4 className="rowTitle">Forma de Pagamento</h4>
            <div className="paymentInfoContainer">
                <img src={cardLogo} alt="Logo do cartao" />
                <div>
                    <p className="paymentDoneText">Cartão com Final {payment_type_reference}</p>
                    <h3 className="totalPrice">Total: {Helper.formatCurrency(order?.ValorTotal)}</h3>
                </div>
            </div>
        </div>
    )

    const order_number_box = (
        <div className="orderNumberContainer">
            <p>Nº do Pedido</p>
            <h2>{order?.NumeroPedido}</h2>
        </div>
    )

    const address_info = (
        <div>
            <h4 className="rowTitle">Endereço de Entrega</h4>
            <div className="alignedContainer">
                <div className='dayItem'>
                    <p>{new Date(order?.DataPrevisãoEntrega).getDate()}</p>
                    <span>{months[new Date(order?.DataPrevisãoEntrega).getMonth() + 1]}</span>
                </div>
                <p className="paymentDoneText">
                    
                    {new Date(order?.DataPrevisãoEntrega).getHours() < 10 
                    ? `0${new Date(order?.DataPrevisãoEntrega).getHours()}` 
                    : new Date(order?.DataPrevisãoEntrega).getHours()}:00 -{' '}

                    {(new Date(order?.DataPrevisãoEntrega).getHours() + 1) < 10
                    ? `0${new Date(order?.DataPrevisãoEntrega).getHours() + 1}` 
                    : new Date(order?.DataPrevisãoEntrega).getHours() + 1
                    }:00

                </p>
            </div>
            <div className="addressTextContainer">
                <p className="paymentDoneText">{order?.NomeCliente}</p>
                <p className="paymentDoneText">{delivery_address?.Endereco} {delivery_address?.Numero}</p>
                <p className="paymentDoneText">{delivery_address?.Complemento}</p>
                <p className="paymentDoneText">{delivery_address?.Bairro}</p>
                <p className="paymentDoneText">{delivery_address?.Cidade} - {delivery_address?.Estado}</p>
                <p className="paymentDoneText">{delivery_address?.CEP}</p>                
            </div>
        </div>
    )

    const review_box_header = (
        <div className="deliveryRevisionHeader">
            {window.innerWidth > 991
                ? (
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="rowTitle">Produto</h4>
                        </div>
                        <div className="col-md-2"/>
                        <div className="col-md-2">
                            <h4 className="rowTitle">Quantidade</h4>
                        </div>
                        <div className="col-md-2">
                            <h4 className="rowTitle">Preço</h4>
                        </div>
                    </div>
                )
                : <h4 className="rowTitle">Produtos</h4>
            }
        </div>
    )

    const order_products_review = (
        <>
            <h4 className="rowTitle">Resumo do Pedido</h4>
            <div className="deliveryRevisionContainer">
                {review_box_header}
                {order?.Produtos.map((product: any) => (
                    <div className="orderRevisionProductsRow">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="orderRevisionProductsItem alignedContainer">
                                    <div className="defaultThumbnail">
                                        <img src={product.PathImg} alt="Imagem do produto" />
                                    </div>
                                    <div className="orderRevisionProductsItemInfos">
                                        <h6>{product.DescricaoDoProduto}</h6>
                                        <span>EAN: {product.CodigoDoProduto}</span>
                                    </div>
                                </div>
                            </div>
                            {window.innerWidth > 768
                                ? (
                                    <>
                                        <div className="col-md-2"/>
                                        <div className="col-md-2">
                                            <div className="centerContent">
                                                <p className="paymentDoneText">{product.Quantidade < 10 ? `0${product.Quantidade}` : product.Quantidade}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="centerContent">
                                                <h3 className="totalPrice">{Helper.formatCurrency(product.ValorUnitario)}</h3>
                                            </div>
                                        </div>
                                    </>
                                )
                                : (
                                    <div className="alignedContainer">
                                        <p className="paymentDoneText">{product.Quantidade < 10 ? `0${product.Quantidade}` : product.Quantidade}</p>
                                        <h3 className="totalPrice">{Helper.formatCurrency(product.ValorUnitario)}</h3>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ))}
            </div>
        </>
    )

    return (
        <div className="paymentDonePage">
            <section className="defaultGraySection fullScreamSizeSection">
                <div className="container">
                    {greetings_message_section}
                    <div className="defaultDivider" />
                    <div className="paymentDetailRow">
                        <div className="row">
                            <div className="col-md-4">
                                {payment_types_info}
                            </div>
                            <div className="col-md-4">
                                {address_info}
                            </div>
                            <div className="col-md-4">
                                {order_number_box}
                            </div>
                        </div>
                    </div>
                    {order_products_review}
                </div>
            </section>
        </div>
    );
}