import { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { AddressType } from '../../../../types';
import UserContext from '../../../../context/user';
import LoaderContext from '../../../../context/loader';

import truckIcon from '../../../../assets/images/truck.png';
import checkIcon from '../../../../assets/images/check_circle.png';
import errorIcon from '../../../../assets/images/error.png';
import './style.scss';

import TextBtn from '../../../../components/buttons/text-btn';
import FormInput from '../../../../components/forms/form-input';
import DefaultBtn from '../../../../components/buttons/default-btn';

interface CheckAddressModalProps {
  is_open: boolean,
  setIsOpen: Function,
  user_address: AddressType | null,
  setUserAddress: Function,
  can_deliver: boolean,
  handleCepAvaiability: Function,
  pesquisaCep: Function
}

export default function CheckAddressModal(props: CheckAddressModalProps) {
  const { is_open, user_address, can_deliver } = props;
  const { setIsOpen, setUserAddress, handleCepAvaiability, pesquisaCep } = props;
  
  const { setLoading } = useContext(LoaderContext);
  const { user } = useContext(UserContext);

  const history = useHistory();

  const [cep, setCep] = useState('');


  const handleSaveAddress = async (cep: string) => {
    setIsOpen(false);
    setLoading(true);
    console.clear();
    
    const address = await pesquisaCep(cep);
    console.log('endereco --> ', address);
    if (address) {      
      setUserAddress(address);
      localStorage.setItem('user_address', address.CEP);
      await handleCepAvaiability(address.CEP);
    }
    setLoading(false);
  }

  const modal_content = (
    user_address && !can_deliver
      ? (
        <div className="cantDeliverContainer">
            <h3>Não entregamos no seu endereço</h3>
            <h4>{user_address.CEP}</h4>
            <p>{user_address.Endereco}</p>
            <TextBtn
                text="Novo endereço"
                onClick={() => {
                  if (user) history.push('/register-address');
                  else {
                    localStorage.removeItem('user_address');
                    setUserAddress(null);
                  }
                }}
            />
        </div>
      )
      : (
        <div className="checkRegionCepContainer">
            <h4>Veja se entregamos na sua região</h4>
            <p>Informe seu CEP abaixo para verificarmos a disponibilidade de entrega</p>
            <div className="row">
                <div className="col-md-7">
                    <FormInput
                        name="cep"
                        mask="99.999-999"
                        value={cep}
                        onChange={setCep}
                        placeholder="Digite seu CEP"
                        customInputStyle={{background: '#F4F4F4', border: 'none'}}
                    />
                </div>
                <div className="col-md-5">
                    <DefaultBtn 
                        text="Verificar"
                        onClick={() => handleSaveAddress(cep)}
                        backgroundColor="#3B413C"
                        textColor="#FFF"
                    />
                </div>
            </div>
        </div>
      )
  );

  return (
    <Modal
        className="defaultModal" 
        show={is_open} 
        onHide={() => setIsOpen(false)}
        centered
    >
        <button className="closeModal" onClick={() => setIsOpen(false)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8332 5.34175L14.6582 4.16675L9.99984 8.82508L5.3415 4.16675L4.1665 5.34175L8.82484 10.0001L4.1665 14.6584L5.3415 15.8334L9.99984 11.1751L14.6582 15.8334L15.8332 14.6584L11.1748 10.0001L15.8332 5.34175Z" fill="black"/>
            </svg>
        </button>
        <div className="defaultModalContent">
            <img src={(user_address && !can_deliver) ? errorIcon : truckIcon} alt="Icone do modal" />
            {modal_content}
        </div>
    </Modal>
  );
}