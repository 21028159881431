import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
 
import LoaderContext from "../../../context/loader";
import CartContext from "../../../context/cart";
import UserContext from "../../../context/user";
import { ProdutosType, ProductImageType, CarrinhoType, FavoritosType } from "../../../types";

import CustomHeader from "../../../components/custom/custom-header";
import NewsletterSection from "../../../components/sections/newsletter-section";
import OutlineBtn from "../../../components/buttons/outline-btn";
import TextBtn from '../../../components/buttons/text-btn';
import BreadCrumps from "../../../components/breadcrumps";
import RangeInput from "../../../components/forms/range-input";
import ProductsSection from "../../../components/sections/products-section";
import Loader from "../../../components/loader";

import "./style.scss";

import { Helper } from "../../../util";
import { MainApi, ProfileApi } from "../../../services/api-routes";

export default function ProductPage() {
    const { setLoading } = useContext(LoaderContext);
    const { addItem, itens, deleteItem } = useContext(CartContext);
    const { user, favorites, setFavorites, removeFavorite } = useContext(UserContext);

    const location = useLocation();
    const history = useHistory();

    const [is_loading, setIsLoading] = useState(false);
    const [product_quantity, setProductQuantity] = useState(0);

    const [product, setProduct] = useState<ProdutosType | null>(null);
    const [is_favorite, setIsFavorite] = useState<FavoritosType | null>(null);
    const [is_desc_text_open, setIsDescTextOpen] = useState(false);
    const [selected_img, setSelectedImg] = useState<string>('');
    const [related_products, setRelatedProduct] = useState<ProdutosType[]>([]);

    const [back_page_name, setbackPageName] = useState('');
    const [back_page_state, setbackPageState] = useState<object | null>(null);

    const descRef = useRef<HTMLParagraphElement>(null);
    const sectionRef = useRef<HTMLDivElement>(null);

    const removeFromFavorites = async (favorite_product_id: number) => {
        await ProfileApi.favorite.delete(favorite_product_id);
        removeFavorite(favorite_product_id);
        setIsFavorite(null);
    }

    const addToFavorites = async () => {
        let body = {
            Id: 0,
            SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
            ClienteCPFCNPJ: user?.IdClienteCPFCNPJ,
            CodigoDoProduto: product?.CodigoDoProduto
        }        
        const resp = await ProfileApi.favorite.create(JSON.stringify(body));
        if (resp) {
            let new_favs = favorites;
            new_favs.push(resp);
            setFavorites(new_favs);
            setIsFavorite(resp);
        }
    }

    const handleFavoriteActions = async () => {
        if (user) {
            if (!is_favorite) await addToFavorites();
            else await removeFromFavorites(is_favorite.Id);
        } else history.push('/sign-in');
    }

    const handleFavoriteProduct = (product_code: string) => {
        const is_favorite_prod = favorites.find((favorite: FavoritosType) => favorite.CodigoDoProduto === product_code);
        if (is_favorite_prod) setIsFavorite(is_favorite_prod);
    }

    const handleProductQuantity = useCallback((product_code: string) => {
        const product_in_cart = itens.find((item: CarrinhoType) => item.CodigoDoProduto === product_code);
        if (product_in_cart) setProductQuantity(product_in_cart.Quantidade);
    }, [itens])

    const handleRelatedProducts = async (brand_code: string) => {
        const resp = await MainApi.produtos.getByBrands(brand_code, '', 1, 20);
        if (resp) setRelatedProduct(resp.data);
    }

    const handleFilterType = (filter_type: string, product: ProdutosType) => {
        if (filter_type) {
            if (filter_type === 'Departamento') {
                setbackPageName(product.Departamento);
                setbackPageState({category: {Id: product.CodigoDepartamento, DescricaoDepartamento: product.Departamento}});
            } else if (filter_type === 'Marca') {
                setbackPageName(product.Marca);
                setbackPageState({brand: {Id: product.CodigoMarca, DescricaoDepartamento: product.Marca}});
            } else if (filter_type === 'Favoritos') {
                setbackPageName(filter_type);
            } else {
                setbackPageName(`Resultado de: ${filter_type}`);
                setbackPageState({search_value: filter_type});
            }
        } else {
            setbackPageName(product.Departamento);        
            setbackPageState({category: {Id: product.CodigoDepartamento, DescricaoDepartamento: product.Departamento}});
        }
    }

    const setData = async (filter_type: string, product: ProdutosType) => {
        setProduct(product);
        setSelectedImg(product.UrlsImagensDoProduto[0].Path);
        handleFilterType(filter_type, product);
        await handleRelatedProducts(product.CodigoMarca.toString());
        handleFavoriteProduct(product.CodigoDoProduto);
        handleProductQuantity(product.CodigoDoProduto);
    }

    const getData = useCallback(async () => {
        const { product, filter_type, productCode } = location.state;
        if (product) await setData(filter_type, product);
        else if (productCode) {
            const product = await MainApi.produtos.show(productCode);
            if (product && !product.Message) await setData(filter_type, product);
        }
    }, [location])

    const handleAddProductToCart = async () => {
        setIsLoading(true);
        if (product_quantity > 0) await addItem(product?.CodigoDoProduto, product_quantity, product?.ValorProduto);
        else await deleteItem(product?.CodigoDoProduto);
        setIsLoading(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        
        (async () => {
            setLoading(true);
            await getData();
            setLoading(false);
        })();
    }, [getData, setLoading]);

    useEffect(() => {
        if (product) handleProductQuantity(product.CodigoDoProduto)
    }, [product, handleProductQuantity])

    return (
        <div ref={sectionRef} className="productPage">
            <div className="defaultPageContent">
                <section className="defaultWhiteSection">
                    <Loader visivel={is_loading} isContentLoader />
                    <div className="container">
                        <BreadCrumps 
                            items={[
                                {
                                    pageName: back_page_name, 
                                    currentPage: false, 
                                    pagePath: back_page_name !== 'Favoritos' ? '/category' : '/favoritos',
                                    state: back_page_state
                                },
                                {pageName: product?.DescricaoResumida && product?.DescricaoResumida.length > 24 ? product?.DescricaoResumida.slice(0, 24).concat('...') : product?.DescricaoResumida, currentPage: true, pagePath: ''},
                            ]}
                        />
                        <div className="row">
                            <div className="col-md-5">
                                <div className="thumbnailImgContainer">
                                    <button className="favoriteBtn" onClick={() => handleFavoriteActions()}>
                                        {is_favorite === null
                                            ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.5 3C14.76 3 13.09 3.81 12 5.09C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.42 2 8.5C2 12.28 5.4 15.36 10.55 20.04L12 21.35L13.45 20.03C18.6 15.36 22 12.28 22 8.5C22 5.42 19.58 3 16.5 3ZM12.1 18.55L12 18.65L11.9 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 5.99 11.07 7.36H12.94C13.46 5.99 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55Z" fill="black"/>
                                            </svg>
                                            : <svg width="24" height="24" viewBox="0 0 24 24">
                                                <path fill="#E43232" d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />
                                            </svg>                                        
                                        }
                                    </button>
                                    {selected_img !== '' && <img src={selected_img} alt="Imagem 1 do produto" />}
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="productDetailContainer">
                                    <h1>{product?.DescricaoResumida}</h1>
                                    <div className="alignedContainer">
                                        <p>GTIN: {product?.CodigoDoProduto}</p>
                                        <p>Entregue por: <TextBtn customStyle={{fontSize: "13px"}} text={product?.Marca} onClick={() => history.push({pathname: `/category`, state: {brand: {Id: product?.CodigoMarca, DescricaoMarca: product?.Marca}}})} /></p>
                                    </div>
                                    <p ref={descRef} style={{height: is_desc_text_open ? descRef?.current?.scrollHeight : 72}} className="productDescription">
                                        {product?.DescricaoCompleta}
                                        {'\n'}
                                        {product?.InformacoesAdicionais ? ReactHtmlParser(product?.InformacoesAdicionais) : ''}
                                    </p>
                                    <TextBtn 
                                        text={is_desc_text_open ? "Ler menos" : "Ler mais"} 
                                        onClick={() => setIsDescTextOpen(oldState => !oldState)} 
                                        customStyle={{marginBottom: "32px"}}
                                    />
                                    <h1>{Helper.formatCurrency(product?.ValorProduto)}</h1>
                                    <div className="selectThumbnailImgContainer">
                                        <p>Mais Opções</p>
                                        <div className="alignedContainer">
                                            {product?.UrlsImagensDoProduto.map((productImg: ProductImageType) => (
                                                <button 
                                                    key={`img-${productImg.Id}`} 
                                                    className={`defaultThumbnail ${selected_img === productImg.Path && "selected"}`}
                                                    onClick={() => setSelectedImg(productImg.Path)}
                                                >
                                                    <img src={productImg.Path} alt="Imagem do produto" />
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="alignedContainer btnContainer">
                                        <RangeInput 
                                            addFunction={() => setProductQuantity(oldState => oldState + 1)}
                                            removeFunction={() => setProductQuantity(oldState => oldState - 1)}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setProductQuantity(parseInt(event.target.value))}
                                            value={product_quantity}
                                            customStyle={{marginRight: window.innerWidth > 991 ? "30px" : 0, maxWidth: window.innerWidth > 991 ? '132px' : '100%'}}
                                        />
                                        <OutlineBtn 
                                            text="Adicionar" 
                                            customStyle={{maxWidth: window.innerWidth > 991 ? "215px" : "100%"}}
                                            onClick={handleAddProductToCart}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {related_products.length > 0 && (
                    <ProductsSection 
                        title="Produtos Relacionados" 
                        customStyle={{padding: window.innerWidth > 991 ? "48px 0 90px 0" : "48px 0"}} 
                        data={related_products}
                        idReference="Id"
                        descricaoReference="DescricaoResumida"
                        valorReference="ValorProduto"
                        imageReference="UrlsImagensDoProduto"
                        secondaryImageReference="Path"
                        productCodeReference="CodigoDoProduto"
                    />
                )}
            </div>
        </div>
    );
}