import { ReactElement } from 'react';

import OutlineBtn from '../../../../../components/buttons/outline-btn';
import SecondaryCardSelectItem from '../../../../../components/cards/secondary-card-select-item';

import { CardsType } from '../../../../../types';

import cardLogo from '../../../../../assets/images/MasterCard.png';

interface CardsListProps {
    user_cards: CardsType[],
    selected_card: number | null,
    setSelectedCard: Function,
    setCardSedurityCode: Function,
    setAddNewCard: Function,
    custom_form: ReactElement
}

export default function CardsList(props: CardsListProps) {
    const { user_cards, selected_card, setSelectedCard, setCardSedurityCode, setAddNewCard, custom_form } = props;

    return (
        <div>
            {user_cards.map((card: CardsType) => (
                <SecondaryCardSelectItem
                    key={`card-${card.Id}`}
                    value={card.Id}
                    title={`${card.BandeiraCartao} ****${card.NumeroCartao.substr(card.NumeroCartao.length - 4)}`}
                    onChange={(value: string) => {
                        setSelectedCard(parseInt(value));
                        setCardSedurityCode('');
                    }}
                    selected={selected_card === card.Id}
                    icon={<img src={cardLogo} alt="Logo do cartao" />}
                    custom_form={custom_form}
                />
            ))}
            <div className="alignRightContainer">
                <OutlineBtn
                    text="Adicionar Cartão"
                    onClick={() => setAddNewCard(true)}
                    textColor="#3B413C"
                    borderColor="#3B413C"
                    customStyle={{maxWidth: '170px'}}
                />
            </div>
        </div>
    );
}
