import { useState, useEffect, useContext, useCallback } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { toast } from "react-toastify"; 
import { useHistory } from "react-router-dom";

import { MainApi } from "../../../services/api-routes";
import { StorageService } from "../../../services";

import LoaderContext from "../../../context/loader";
import UserContext from "../../../context/user";
import CartContext from "../../../context/cart";
import { AddressType, BannerType, DepartamentosType, ProdutosType } from '../../../types';

import CustomHeader from "../../../components/custom/custom-header";
import CategoriesCard from "../../../components/cards/categories-card";
import NewsletterSection from "../../../components/sections/newsletter-section";
import ProductsSection from "../../../components/sections/products-section";
import CheckAddressModal from "./check-address-modal";

import categoryImg from '../../../assets/images/category-img.png';

import './style.scss';
import { Helper } from "../../../util";
import { Console } from "console";

export default function HomePage(){
  const { setLoading } = useContext(LoaderContext);
  const { user, delivery_address, authenticated, setAuthenticated } = useContext(UserContext);

  const history = useHistory();

  SwiperCore.use([Navigation, Pagination]);

  const [is_address_modal_open, setIsAddressModalOpen] = useState(false);
  const [can_deliver, setCanDeliver] = useState(false);
  const [user_address, setUserAddress] = useState<AddressType | null>(null);

  const [banner, setBanner] = useState<BannerType[]>([]);
  const [categories, setCategories] = useState<DepartamentosType[]>([]);

  const [best_sellers_products, setBestSellersProducts] = useState<ProdutosType[]>([]);
  const [promotion_products, setPromotionProducts] = useState<ProdutosType[]>([]);
  const [highlights_products, setHighlightsProducts] = useState<ProdutosType[]>([]);

  const getBanner = async () => {
    const resp = await MainApi.highlights.getBanner();
    if (resp) setBanner(resp);
  }

  const getDepartments = async (term: string, page: number, pageSize: number) => {
    const departments = await MainApi.departamento.index(term, page, pageSize);
    if (departments) {
      const categories = departments.data.filter((department: DepartamentosType) => department.DestaqueBanner);
      setCategories(categories);
    }
  }

  const getBestSellersProducts = async (term: string, page: number, pageSize: number) => {
    const best_sellers_resp = await MainApi.produtos.getByBestSellers(term, '', page, pageSize);
    if (best_sellers_resp) setBestSellersProducts(best_sellers_resp.data);
  }

  const getPromotionProducts = async (term: string, page: number, pageSize: number) => {
    const promotion_resp = await MainApi.produtos.getByPromotion(term, '', page, pageSize);
    if (promotion_resp) setPromotionProducts(promotion_resp.data);
  }

  const getHightlightProducts = async (page: number, pageSize: number) => {
    let document = '07713031812';
    const resp = await MainApi.highlights.get(Helper.getFornecedorDocument(), document, page, pageSize);
    if (resp) setHighlightsProducts(resp.data);
  }

  const pesquisaCep = async (cep: string) => {
    var raw_cep = cep.replace('.', '').replace('-', '');
    if(raw_cep.length >= 8){
      try {
        setLoading(true);
        let reqParams = {
          method: 'GET',
        }
    
        let url_completa = new URL(`https://viacep.com.br/ws/${raw_cep}/json/`).toString();
    
        let resp = await fetch(url_completa, reqParams);
        let json = await resp.json();
        
        if(json && !json.erro) {
          const address = {
            Id: 0,
            Bairro: json.bairro,
            CEP: raw_cep,
            Cidade: json.localidade,
            ClienteCPFCNPJ: '',
            Complemento: '',
            Endereco: json.logradouro,
            EnderecoResidencialComercial: '',
            Estado: json.uf,
            Numero: '',
            Obs: '',
            SiteFornecedorCNPJ: '',
            TelefoneContato: ''
          }
          return address;
        } else {
          setIsAddressModalOpen(true);
          console.log('Aqui.....');
          toast.error("CEP inválido!", {autoClose: false, toastId: 'toastCEPError'});
        }
        setLoading(false);
      } catch(e) {
        toast.error("Erro ao carregar endereço!", {autoClose: false, toastId: 'toastAddressError'})
      }
    }
  }

  const handleCepAvaiability = async (cep: string) => {
    const resp = await MainApi.baseCep.get(cep);
    console.log("CEP: ", cep);
    if (resp && !resp.Message) {
      if (resp.ValorFreteLocal) {
        setIsAddressModalOpen(false);
        setCanDeliver(true);
        console.log('Entrega no endereço: -->> ', resp.Endereco);
      } else setIsAddressModalOpen(true);
    } else setIsAddressModalOpen(true);
  }

  const getAddress = useCallback(async () => {
    if (user && delivery_address) {
      setUserAddress(delivery_address);
      await handleCepAvaiability(delivery_address.CEP);
    }
    else if (!StorageService.isAuthenticated()) {
      const storage_cep = localStorage.getItem('user_address');
      if (storage_cep) {
        const address = await pesquisaCep(storage_cep);
        if (address) {
          setUserAddress(address);
          await handleCepAvaiability(address.CEP);
        }
      }
      else setIsAddressModalOpen(true);
    }
  }, [user, delivery_address])

  const getData = useCallback(async () => {
    if (authenticated) {
      var resps = [
        getDepartments('', 1, 20), 
        getBestSellersProducts('', 1, 20),
        getPromotionProducts('', 1, 20),
        getHightlightProducts(1, 20), 
        getAddress(),
        getBanner()
      ];
  
      await Promise.all(resps)
      .then(() => {})
      .catch((e) => {
        toast.error("Erro ao carregar página inicial!");
        //console.log('Error', e);
      });
    } else if (!StorageService.isAuthenticated()) {     
        const token = await MainApi.user.publicAuth(Helper.getFornecedorDocument());
        //console.log('public auth', token)
        if (token) {
          StorageService.setToken(token);
          setAuthenticated(true);
        }
    }
  }, [authenticated, getAddress]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getData();
      setLoading(false);
    })();

    window.scrollTo(0, 0);
  }, [getData, setLoading]);

  return (
    <>
      <div className="defaultPageContent">
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation={window.innerWidth > 991}
            pagination={window.innerWidth < 991}
            className="homeCarroussel"
            loop
        >
          {banner.map((item: BannerType) => {
            let pathname = '/category';
            let state = {};

            if (item.TipoDestaque === 'Produto') {
              pathname = '/product';
              state = {productCode: item.Codigo};
            } 
            else if (item.TipoDestaque === 'Marca' || 
              item.TipoDestaque === 'Departamento') state = {bannerInfo: item};

            return (
              <SwiperSlide key={`banner-${item.Id}`}>
                <button onClick={() => history.push({pathname, state})} className="bannerItem">
                    <img src={item.PathImg} alt={`Banner de ${item.DescricaoResumida}`} />
                </button>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <section className="defaultWhiteSection">
          <div className="container">
            <Swiper
                spaceBetween={12}
                slidesPerView={window.innerWidth > 991 ? 3 : 1}
                navigation
                loop
            >
              {categories.map((category: DepartamentosType) => (
                <SwiperSlide key={category.Id}>
                    <CategoriesCard 
                        name={category.DescricaoDepartamento}
                        path={{pathname: `/category`, state: {category}}}
                        image={categoryImg}
                    />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        {highlights_products.length > 0 && (
          <ProductsSection 
            title="Destaques" 
            customStyle={{padding: "64px 0 0 0"}} 
            data={highlights_products}
            idReference="Id"
            descricaoReference="DescricaoResumida"
            valorReference="Valor"
            imageReference="PathImg"
            productCodeReference="Codigo"
          />)
        }
        {best_sellers_products.length > 0 && (
          <ProductsSection 
            title="Mais Vendidos" 
            data={best_sellers_products}
            customStyle={{padding: window.innerWidth > 991 ? "64px 0 32px 0" : "32px 0 18px 0"}} 
            idReference="Id"
            descricaoReference="DescricaoResumida"
            valorReference="ValorProduto"
            imageReference="UrlsImagensDoProduto"
            secondaryImageReference="Path"
            productCodeReference="CodigoDoProduto"
          />
        )}
        {promotion_products.length > 0 && (
          <ProductsSection 
            title="Em Promoção" 
            customStyle={{padding: window.innerWidth > 991 ? "40px 0 64px 0" : "32px 0 40px 0"}} 
            data={promotion_products}
            idReference="Id"
            descricaoReference="DescricaoResumida"
            valorReference="ValorProduto"
            imageReference="UrlsImagensDoProduto"
            secondaryImageReference="Path"
            productCodeReference="CodigoDoProduto"
          />
        )}
      </div>
      <CheckAddressModal 
        is_open={is_address_modal_open}
        setIsOpen={setIsAddressModalOpen}
        pesquisaCep={pesquisaCep}
        user_address={user_address}
        setUserAddress={setUserAddress}
        can_deliver={can_deliver}
        handleCepAvaiability={handleCepAvaiability}
      />
    </>
  );
}