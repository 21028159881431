import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

import { DepartamentosType } from '../../types';

interface NavigationProps {
    customStyle?: object
    options: DepartamentosType[]
}

export default function Navigation(props: NavigationProps){
    const { customStyle, options } = props;

    const dropDownRef = useRef<HTMLUListElement>(null);

    const [is_drop_down_open, setIsDropDownOpen] = useState(false);
    
    return (
        <nav style={customStyle} className="navigationContainer">
            <div className="container">
                <div 
                    className="allCategoryDropDownContainer"
                    onMouseLeave={() => setIsDropDownOpen(false)}
                >
                    <button 
                        className="menuBurgerBtn"
                        onMouseEnter={() => setIsDropDownOpen(true)}
                    >
                        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="black"/>
                        </svg>
                        Todas as Categorias
                    </button>
                    <ul 
                        ref={dropDownRef} 
                        className={`allCategoryDropDownContent defaultScrollContainer ${is_drop_down_open ? 'open' : ''}`}
                        style={{height: is_drop_down_open ? 320 : 0}}
                    >
                        {options.map((option: DepartamentosType) => (
                            <li key={`allCats-${option.Id}`}>
                                <Link to={{pathname: `/category`, state: {category: option}}}>
                                    {option.DescricaoDepartamento}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <ul className="menuOptionsContainer">
                    {options.slice(0, 6).map((option: DepartamentosType) => (
                        <li key={option.Id}>
                            <Link to={{pathname: `/category`, state: {category: option}}}>
                                {option.DescricaoDepartamento}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    )
} 