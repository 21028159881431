import { ReactChild, ReactChildren } from "react";

import CustomHeader from "../custom/custom-header";
import NewsletterSection from "../sections/newsletter-section";

interface LayoutProps {
    children: ReactChild | ReactChildren,
    layout: string
}

export default function Layout(props: LayoutProps) {
  const { children, layout } = props;

  // layout types -> BlankLayout, ShopLayout, ShopLayout

  return (
      <>
        <CustomHeader
            hasSearch={layout !== 'BlankLayout'}
            hasRightActions={layout !== 'BlankLayout'}
            hasNavigation={layout !== 'BlankLayout'}
        />
        {children}
        {layout !== 'BlankLayout' && <NewsletterSection />}
      </>
  )
}