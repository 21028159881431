import { deleteReq, getReq, postReq, putReq } from "../../services/api";

const user = {
  publicAuth:       async (fornecedor: string) => { return await getReq('usuário', `sessao/autenticarperfil/${fornecedor}`, true) },
  get:              async () => { return await getReq('usuário', 'sessao/GetUsuario', true) },
  getById:          async (id: string) => { return await getReq('usuário', `cliente/${id}`, true) },
  isAuthenticated:  async () => { return await getReq('autenticado', 'sessao/Autenticado', true) },
  getDocument:      async (document: string) => { return await getReq('usuário', `cliente/GetClienteCPFCNPJ/${document}`, true) },
  update:           async (body: string) => { return await putReq('usuário', `Cliente`, body, true, false) },
}

const departamento = {
  show:       async (id: number) => { return await getReq('departamento', `departamento/GetDepartamento/${id}`, true) },
  index:      async (term: string, page: number, pageSize: number) => { return await getReq('departamentos', `Departamento?term=${term}&page=${page}&pageSize=${pageSize}`, true) },
}

const produtos = {
  show:                 async (GTIN: string) => { return await getReq('produto', `produto/GetProduto?GTIN=${GTIN}`, true) },
  index:                async (term: string, ordem: string, page: number, pageSize: number) => { return await getReq('produtos', `Produto?term=${term}&ordem=${ordem}&page=${page}&pageSize=${pageSize}`, true) },
  getByDepartment:      async (term: string, ordem: string, page: number, pageSize: number) => { return await getReq('produtos', `Produto/GetProdutosPorDepartamento?term=${term}&ordem=${ordem}&page=${page}&pageSize=${pageSize}`, true) },
  getByBrands:          async (term: string, ordem: string, page: number, pageSize: number) => { return await getReq('produtos', `Produto/GetProdutosPorMarca?term=${term}&ordem=${ordem}&page=${page}&pageSize=${pageSize}`, true) },
  getByPromotion:       async (term: string, ordem: string, page: number, pageSize: number) => { return await getReq('produtos', `Produto/GetProdutosEmPromacao?term=${term}&ordem=${ordem}&page=${page}&pageSize=${pageSize}`, true) },
  getByBestSellers:     async (term: string, ordem: string, page: number, pageSize: number) => { return await getReq('produtos', `Produto/GetProdutosMaisVendidos?term=${term}&ordem=${ordem}&page=${page}&pageSize=${pageSize}`, true) },
}

const highlights = {
  get:              async (fornecedor: string, document: string, page: number, pageSize: number) => { return await getReq('produtos', `Destaque?siteFornecedorCNPJ=${fornecedor}&clienteCPFCNPJ=${document}&page=${page}&pageSize=${pageSize}`, true) },
  getBanner:        async () => { return await getReq('produtos', `Destaque/GetCarrocel`, true) },
}

const warnings = {
  index:       async (fornecedor_doc: string, user_document: string, page: string, pageSize: string) => { return await getReq('avisos', `Aviso?siteFornecedorCNPJ=${fornecedor_doc}&clienteCPFCNPJ=${user_document}&page=${page}&pageSize=${pageSize}`, true) },
  create:      async (body: string) => { return await postReq('aviso', `Aviso`, body, true) },
  delete:      async (id: number) => { return await deleteReq('usuário', `Aviso/${id}`, true) },
}

const baseCep = {
  get:    async (cep: string) => { return await getReq('CEP Base', `BaseCep/GetCep/${cep}`, true, true) },
}

export {
  user,
  departamento,
  produtos,
  warnings,
  baseCep,
  highlights
}