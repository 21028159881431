import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';

import LoaderContext from '../../../context/loader';
import UserContext from '../../../context/user';
import { AddressType } from '../../../types';

import FormInput from '../../../components/forms/form-input';
import DefaultBtn from '../../../components/buttons/default-btn';
import CustomHeader from '../../../components/custom/custom-header';
import TextBtn from '../../../components/buttons/text-btn';

import './style.scss';
import { Helper } from '../../../util';
import { ProfileApi } from '../../../services/api-routes';
import SelectInput from '../../../components/forms/select-input';

export default function RegisterAddressPage(){
  const { setLoading } = useContext(LoaderContext);
  const { user, setDeliveryAddress } = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();

  const [address, setAddress] = useState<AddressType | null>(null);
  const [cep, setCep] = useState('');
  const [street, setStreet] = useState('');
  const [residence_number, setResidenceNumber] = useState('');
  const [residence_complement, setResidenceComplement] = useState('');
  const [neighbor, setNeighbor] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [reference_point, setReferencePoint] = useState('');
  const [address_type, setAddressType] = useState('');

  const [invalid_cep, setInvalidCep] = useState(false);
  const [invalid_street, setInvalidStreet] = useState(false);
  const [invalid_residence_number, setInvalidResidenceNumber] = useState(false);
  const [invalid_neighbor, setInvalidNeighbor] = useState(false);
  const [invalid_city, setInvalidCity] = useState(false);
  const [invalid_uf, setInvalidUf] = useState(false);

  const getData = useCallback(() => {
        if (location.state) {
            const { address } = location.state;
            setAddress(address);
            if (address) {
                setCep(address.CEP);
                setStreet(address.Endereco);
                setNeighbor(address.Bairro);
                setCity(address.Cidade);
                setUf(address.Estado);
                setAddressType(address.EnderecoResidencialComercial);
                setResidenceComplement(address.Complemento)
                setResidenceNumber(address.Numero);
                setReferencePoint(address.Obs);
            }
        }
  }, [])

  const pesquisaCep = async (cep: string) => {
    setCep(cep);
    let raw_cep = cep.replace('.', '').replace('-', '').replaceAll('_', '');
    if(raw_cep.length >= 8){
      try {
        setLoading(true);
        let reqParams = {
          method: 'GET',
        }
    
        let url_completa = new URL(`https://viacep.com.br/ws/${raw_cep}/json/`).toString();
    
        let resp = await fetch(url_completa, reqParams);
        let json = await resp.json();
        
        if(json && !json.erro){
          setStreet(json.logradouro);
          setCity(json.localidade);
          setNeighbor(json.bairro);
          setUf(json.uf);
          if (json.logradouro !== '') setInvalidStreet(false);
          if (json.localidade !== '') setInvalidCity(false);
          if (json.bairro !== '') setInvalidNeighbor(false);
          if (json.uf !== '') setInvalidUf(false)
          setLoading(false);
        } else {
          setStreet('');
          setCity('');
          setNeighbor('');
          setUf('');
          toast.error("Erro ao carregar endereço!", {autoClose: false, toastId: 'toastCEPError'});
        }
        setLoading(false);
      } catch(e) {
        toast.error("Erro ao carregar endereço!", {autoClose: false, toastId: 'toastAddressError'})
      }
    }
  }

  const handleRegisterAddress = async () => {
    setLoading(true);
    if (!verifyParams()) {
        if (user) {
            const body = {
                Id: address ? address.Id : 0,
                SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
                ClienteCPFCNPJ: user.IdClienteCPFCNPJ,
                CEP: cep.replace('.', '').replace('-', '').replaceAll('_', ''),
                Endereco: street,    
                Bairro: neighbor,
                Cidade: city,
                Estado: uf,
                Numero: residence_number,
                Complemento: residence_complement,
                TelefoneContato: user.TelefoneCelular,
                EnderecoResidencialComercial: address_type,
                Obs: reference_point
            };
    
            if (address) {
                const resp = await ProfileApi.address.update(JSON.stringify(body));
                if (!resp) {
                    toast.success('Endereço de entrega atualizado com sucesso!', {toastId: 'addressUpdateSuccess'});
                    setDeliveryAddress(body);
                    history.goBack();
                }
            } else {
                const resp = await ProfileApi.address.create(JSON.stringify(body));
                if (resp && !resp.Message) {
                    toast.success('Endereço de entrega cadastrado com sucesso!', {toastId: 'addressRefisterSuccess'});
                    setDeliveryAddress(resp);
                    if (location.state) {
                        const { is_continue_register_action, is_payment_flow } = location.state;
                        if (is_continue_register_action) {
                            history.push({pathname: '/'});
                            return;
                        } else if (is_payment_flow) {
                            history.push('/cart-info');
                            return;
                        }
                    }
                    history.goBack();
                }
            }
        }
    }
    setLoading(false);
  }

  const verifyParams = () => {
    let invalid = false;

    let raw_cep = cep.replace('.', '').replace('-', '').replaceAll('_', '');
    if (
        raw_cep.length < 8 ||
        street === '' ||
        neighbor === '' ||
        city === '' ||
        uf === '' ||
        residence_number === ''
    ) {
      invalid = true;
      setInvalidCep(raw_cep.length < 8);
      setInvalidStreet(street === '');
      setInvalidNeighbor(neighbor === '');
      setInvalidCity(city === '');
      setInvalidUf(uf === '');
      setInvalidResidenceNumber(residence_number === '');
      toast.error('Verifique os dados do endereço!', {toastId: 'addressError'})
    }
    
    return invalid;
  }

  useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
  }, [getData, setLoading])

  return (
    <div className="registerAddressPage">
      <section className="defaultGraySection fullScreamSizeSection">
        <div className="container">
            <div className="signContainer">
                <div className="col-md-8">
                    <div className="spacedContainer">
                        <h1 className="formsTitle">Endereço de Entrega</h1>
                        <TextBtn text="Voltar" onClick={() => address ? history.goBack() : history.push('/')} />
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormInput 
                                mask="99.999-999"
                                type="text"
                                label='CEP'
                                name='cep'
                                value={cep}
                                onChange={pesquisaCep}
                                required={true}
                                invalid={invalid_cep}
                                setInvalid={setInvalidCep}
                            />
                        </div>
                    </div>
                    <FormInput 
                        type="text"
                        label='Endereço'
                        name='address'
                        value={street}
                        onChange={setStreet}
                        required={true}
                        invalid={invalid_street}
                        setInvalid={setInvalidStreet}
                    />
                    <div className="row">
                        <div className="col-md-5">
                            <FormInput 
                                type="text"
                                label='Bairro'
                                name='neighbor'
                                value={neighbor}
                                onChange={setNeighbor}
                                required={true}
                                invalid={invalid_neighbor}
                                setInvalid={setInvalidNeighbor}
                            />
                        </div>
                        <div className="col-md-5">
                            <FormInput 
                                type="text"
                                label='Cidade'
                                name='city'
                                value={city}
                                onChange={setCity}
                                required={true}
                                invalid={invalid_city}
                                setInvalid={setInvalidCity}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormInput 
                                type="text"
                                label='UF'
                                name='uf'
                                value={uf}
                                onChange={setUf}
                                required={true}
                                invalid={invalid_uf}
                                setInvalid={setInvalidUf}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormInput 
                                type="text"
                                label='Número'
                                name='residence_number'
                                value={residence_number}
                                onChange={setResidenceNumber}
                                required={true}
                                invalid={invalid_residence_number}
                                setInvalid={setInvalidResidenceNumber}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormInput 
                                type="text"
                                label='Complemento'
                                name='residence_complement'
                                value={residence_complement}
                                onChange={setResidenceComplement}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <FormInput 
                                type="text"
                                label='Ponto de Referência'
                                name='reference_point'
                                value={reference_point}
                                onChange={setReferencePoint}
                                required={true}
                            />
                        </div>
                        <div className="col-md-4">
                            <SelectInput 
                                label='Tipo de Endereço'
                                name='address_type'
                                value={address_type}
                                onChange={setAddressType}
                                required={true}
                                items={[
                                    {value: 'R', label: 'Residencial'},
                                    {value: 'C', label: 'Comercial'},
                                ]}
                                optionLabelReference='label'
                                optionValueReference='value'
                            />
                        </div>
                    </div>
                    <div className="alignRightContainer">
                        <DefaultBtn 
                            text={address ? "Atualizar" : "Cadastrar"}
                            onClick={() => handleRegisterAddress()}
                            customStyle={{marginTop: '24px'}}
                        />
                    </div>
                </div>
            </div>
        </div>
      </section>
    </div>
  );
}