import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import './style.scss';

import { MainApi } from '../../services/api-routes';
import { StorageService } from '../../services';
import { Helper } from '../../util';

import LoaderContext from '../../context/loader';
import UserContext from '../../context/user';
import CartContext from '../../context/cart';
import { DepartamentosType } from '../../types';

import TextBtn from '../buttons/text-btn';
import DefaultBtn from '../buttons/default-btn';

interface NavigationDrawerProps {
    open: boolean,
    closeAction: Function,
    options: DepartamentosType[]
}

export default function NavigationDrawer(props: NavigationDrawerProps) {
    const { open, closeAction, options } = props;

    const { setLoading } = useContext(LoaderContext);
    const { user, setUser, setFavorites, setDeliveryAddress } = useContext(UserContext);
    const { setItens } = useContext(CartContext);

    const history = useHistory();

    const handleLogout = async () => {
        closeAction();
        setLoading(true);
        localStorage.clear();
        setUser(null);
        setItens([]);   
        setFavorites([]); 
        setDeliveryAddress(null);
        const token = await MainApi.user.publicAuth(Helper.getFornecedorDocument());
        if (token) {
            StorageService.setToken(token);
            history.push('/');
        }
        setLoading(false);
    }
    
    return (
        <>
            <div onClick={() => closeAction()} className={`overlay ${open && "open"}`} />
            <div className={`navigationDrawerContainer ${open && "open"}`}>
                <div className="navigationDrawerHeader">
                    {user !== null
                        ? (
                            <>
                                <button onClick={() => history.push('/personal-info')} className="navItem">Dados Pessoais</button>
                                <button onClick={() => history.push('/addresses')} className="navItem">Endereços</button>
                                <button onClick={() => history.push('/order')} className="navItem">Meus Pedidos</button>
                                <button onClick={() => history.push('/favoritos')} className="navItem">Meus Favoritos</button>
                                <button className="navItem" onClick={() => handleLogout()}>Sair</button>
                            </>
                        )
                        : (
                            <div className="unauthorizedContainerActions">
                                <h3>Você não está logado!</h3>
                                <DefaultBtn
                                    text="Entrar"
                                    onClick={() => history.push('/sign-in')}
                                    customStyle={{maxWidth: '110px', marginBottom: '14px'}}
                                    backgroundColor="#3B413C"
                                    textColor="#FFF"
                                />
                                <p>Não possui conta? <TextBtn text='Cadastrar' customStyle={{marginLeft: '4px', fontSize: '13px'}} onClick={() => history.push('/sign-up')} /></p>
                            </div>
                        )
                    }
                </div>
                <ul className="navItemsContainer defaultScrollContainer">
                    {options.map((option: DepartamentosType) => (
                        <li key={`allCats-${option.Id}`}>
                            <button onClick={() => {
                                closeAction()
                                history.push({pathname: `/category`, state: {category: option}})
                            }}>
                                {option.DescricaoDepartamento}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
} 