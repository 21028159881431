import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import LoaderContext from "../../../context/loader";
import UserContext from "../../../context/user";
import { AgendaType, PedidosType } from "../../../types";

import CustomHeader from "../../../components/custom/custom-header";
import TextBtn from "../../../components/buttons/text-btn";
import DefaultBtn from "../../../components/buttons/default-btn";
import OutlineBtn from "../../../components/buttons/outline-btn";

import './style.scss';
import { Helper } from "../../../util";
import { CartApi } from "../../../services/api-routes";

interface DayType {
    id: number,
    date: Date,
}

export default function ChooseDayPage(){
  const { setLoading } = useContext(LoaderContext);
  const { user, delivery_address } = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();

  const TODAY = new Date();
  const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  const [selected_day, setSelectedDay] = useState<DayType | null>(null);
  const [available_days, setAvailableDays] = useState<DayType[]>([]);
  const [available_times, setAvailableTimes] = useState<AgendaType[]>([]);
  const [selected_time, setSelectedTime] = useState<number>(0);
  const [current_week_date, setCurrentWeekDate] = useState<Date | null>(null);
  
  const handleSchedule = async () => {
    setLoading(true);
    const { delivery_type, discount_value, freight, order_observation } = location.state;
    if (user && selected_day && selected_time !== 0) {
        if (delivery_type !== null && discount_value !== null && freight !== null) history.push({pathname: '/payment-method', state: { delivery_type, discount_value, freight, selected_day: selected_day.date, selected_time, order_observation }})
        else toast.error('Houve algum erro! Retorne ao carrinho e verifique se tudo está ok!', { toastId: 'orderError' })
    } else toast.error('Verifique os dados de agendamento!', {toastId: "scheduleError"})
    setLoading(false);
  }

  const handleSelectDay = async (day: DayType) => {
    setLoading(true);
    setSelectedDay(day);
    await getTimeAvailability(day.date);
    setLoading(false);
  }

  const getTimeAvailability = async (day: Date) => {
    const body = {
        SiteFornecedorCNPJ: Helper.getFornecedorDocument(),
        DataDisponivel: moment(day).format('l')
    }
    const resp = await CartApi.schedule.getAvailability(JSON.stringify(body));
    if (resp) setAvailableTimes(resp)
  }

  const getAvailableDays = async (day: Date) => {
    const days = [
        {id: 1, date: day},
        {id: 2, date: new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)},
        {id: 3, date: new Date(day.getFullYear(), day.getMonth(), day.getDate() + 2)},
        {id: 4, date: new Date(day.getFullYear(), day.getMonth(), day.getDate() + 3)},
        {id: 5, date: new Date(day.getFullYear(), day.getMonth(), day.getDate() + 4)}
    ]
    setAvailableDays(days)
    setSelectedDay(days[0])
    setCurrentWeekDate(day);
    await getTimeAvailability(day);
  }

  const getData = useCallback(async () => {
    setLoading(true);
    const { delivery_type } = location.state;
    if (delivery_type === '3') await getAvailableDays(TODAY);
    else setCurrentWeekDate(null);
    setLoading(false);
  }, [])

  useEffect(() => {
      (async () => {
          await getData();
      })();
  }, [setLoading]);
  
  const select_day = (
        <div className="alignedContainer selectDayContainer">
            {available_days?.map((day: DayType) => (
                <button 
                    key={day.id} 
                    className={`dayItem ${day.id === selected_day?.id ? "selected" : ''}`}
                    onClick={() => handleSelectDay(day)}
                >
                    <p>{day.date.getDate()}</p>
                    <span>{months[day.date.getMonth()]}</span>
                </button>
            ))}
            <DatePicker
                selected={current_week_date}
                onChange={(date) => date && getAvailableDays(date)}
                customInput={(
                    <button className="datePickerBtn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.625 20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V10.7812H2.625V20.625ZM20.625 4.3125H16.6875V2.8125C16.6875 2.70937 16.6031 2.625 16.5 2.625H15.1875C15.0844 2.625 15 2.70937 15 2.8125V4.3125H9V2.8125C9 2.70937 8.91563 2.625 8.8125 2.625H7.5C7.39687 2.625 7.3125 2.70937 7.3125 2.8125V4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V9.1875H21.375V5.0625C21.375 4.64766 21.0398 4.3125 20.625 4.3125Z" fill="white"/>
                        </svg>                                           
                        <span className="tooltip">Clique aqui e selecione outras datas para entrega.</span>
                    </button>
                )}
            />
        </div>
  );

  const time_table = (
        <ul className="timeTableContainer">
            {available_times.map((time: AgendaType, index: number) => (
                <li key={`time-avaiability-${index}`}>
                    <p>{time.HorariosDiponiveis < 10 ? `0${time.HorariosDiponiveis}` : time.HorariosDiponiveis}:00 - {time.HorariosDiponiveis + 1 < 10 ? `0${time.HorariosDiponiveis + 1}` : time.HorariosDiponiveis + 1}:00</p>
                    {time.Indisponivel
                        ? <DefaultBtn 
                            text="Indisponível"
                            onClick={() => console.log('agendar')}
                            disabled
                            customStyle={{height: '32px', maxWidth: '117px', padding: '5px 0', fontSize: '14px'}}
                        />
                        : <OutlineBtn 
                            text={`${selected_time === time.HorariosDiponiveis ? "Selecionado" : "Agendar"}`}
                            onClick={() => setSelectedTime(time.HorariosDiponiveis)}
                            disabled={selected_time === time.HorariosDiponiveis}
                            disabledBackground="#32E464"
                            customStyle={{height: '32px', maxWidth: '117px', padding: '5px 0', fontSize: '14px'}}
                        />
                    }
                </li>
            ))}
        </ul>
  );

  return (
    <div className="chooseDayPage">
      <section className="defaultGraySection fullScreamSizeSection">
          <div className="container">
            <div className="col-md-8">
                    <div className="spacedContainer">
                        <h1 className="formsTitle">Escolha o dia</h1>
                        <TextBtn text="Voltar" onClick={() => history.goBack()} />
                    </div>
                    <p className="availableDatetimeText">Horários disponíveis: 13 Setembro — 18 Setembro</p>
                    {current_week_date && select_day}
                    {time_table}
                    <div className="alignRightContainer">
                        <DefaultBtn 
                            text="Continuar"
                            onClick={() => handleSchedule()}
                        />
                    </div>
            </div>
          </div>
      </section>
    </div>
  );
}