import { Routes } from './services/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LoaderProvider } from "./context/loader";
import { UserProvider } from './context/user';
import { CartProvider } from "./context/cart";

import './styles/global.scss';
import './styles/fonts.scss';


function App() {
  return (
    <LoaderProvider>
      <UserProvider>
        <CartProvider>
            <ToastContainer />
            <Routes />
        </CartProvider>
      </UserProvider>
    </LoaderProvider>
  );
}

export default App;
