import { BrowserRouter, Switch } from "react-router-dom";

import AuthRoutes from "../pages/auth/auth-routes";
import CartRoutes from "../pages/cart/cart-routes";
import MainRoutes from "../pages/main/main-routes";
import ProfileRoutes from "../pages/profile/profile-routes";

export const Routes = () => (
  <BrowserRouter>
    <Switch>
      <>
        {AuthRoutes}
        {CartRoutes}
        {MainRoutes}
        {ProfileRoutes}
      </>
    </Switch>
  </BrowserRouter>
);