import { Fragment } from 'react';
import { useHistory } from 'react-router';

import './style.scss';

interface BreadCrumpType {
    currentPage: boolean,
    pageName: string | undefined,
    pagePath: string,
    state?: object | null
}

interface BreadCrumpsProps {
    items: BreadCrumpType[]
}

export default function BreadCrumps(props: BreadCrumpsProps){
    const { items } = props;

    const history = useHistory();
    
    return (
        <div className="breadCrumpsContainer">
            <button onClick={() => history.push('/')}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
                    <path d="M6.66683 13.3333V9.33333H9.3335V13.3333H12.6668V8H14.6668L8.00016 2L1.3335 8H3.3335V13.3333H6.66683Z" fill="#ABABAB"/>
                </svg>
            </button>
            {items.map((item: BreadCrumpType, index: number) => {
                return (
                    <Fragment key={index}>
                        <svg className="arrows" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M5.72656 11.06L8.7799 8L5.72656 4.94L6.66656 4L10.6666 8L6.66656 12L5.72656 11.06Z" fill="#ABABAB"/>
                        </svg>
                        {item.currentPage 
                            ? (
                                <p>{item.pageName}</p>
                            ) 
                            : (
                                <button onClick={() => history.push({pathname: item.pagePath, state: item.state })}>{item.pageName}</button>
                            )
                        } 
                    </Fragment>          
                )
            })}
        </div>
    )
} 