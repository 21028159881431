import { useContext } from 'react'; 
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import ProductCard from '../../cards/products-card';

import { CarrinhoType, FavoritosType } from '../../../types';
import UserContext from '../../../context/user';
import CartContext from '../../../context/cart';

import './style.scss';

interface ProductsSectionProps {
    title?: string,
    customStyle?: object,
    data?: any[],
    idReference: string,
    descricaoReference: string,
    valorReference: string,
    imageReference: string,
    secondaryImageReference?: string,
    productCodeReference: string
}


export default function ProductsSection(props: ProductsSectionProps){
    const { title, customStyle, data } = props;

    const { idReference, descricaoReference, valorReference, imageReference, secondaryImageReference, productCodeReference } = props;

    const history = useHistory();

    const { favorites } = useContext(UserContext);
    const { itens } = useContext(CartContext);

    SwiperCore.use([Navigation]);

    return (
        <section style={customStyle} className="defaultGraySection productsSection">
            <div className="container">
                <h1 className="defaultSectionTitle">{title}</h1>
                <Swiper                
                    spaceBetween={15}
                    slidesPerView={window.innerWidth > 991 ? 4 : (window.innerWidth > 768 ? 3 : 1)}
                    navigation
                    loop                    
                >
                    {data?.map((item: any) => {
                        const item_in_cart = itens.find((cart_item: CarrinhoType) => cart_item.CodigoDoProduto === item[productCodeReference]);
                        return (
                            <SwiperSlide key={item[idReference]}>
                                <ProductCard                                       
                                    name={item[descricaoReference]}
                                    price={item[valorReference]}
                                    image={secondaryImageReference ? item[imageReference][0][secondaryImageReference] : item[imageReference]}
                                    productCode={item[productCodeReference]}
                                    onClick={() => history.push({pathname: '/product', state: { productCode: item[productCodeReference], filter_type: 'Departamento' }})}
                                    isFavorite={favorites.length > 0 ? (favorites.find((favorite: FavoritosType) => favorite.CodigoDoProduto === item.CodigoDoProduto) ? true : false) : false}
                                    cart_item={item_in_cart}
                                    is_slide_responsive_item
                                />
                            </SwiperSlide>
                        )
                    })} 
                </Swiper>
            </div>
        </section>
    );
}