import './style.scss';

interface DefaultBtnProps {
    text?: string,
    backgroundColor?: string,
    textColor?: string,
    onClick: Function,
    customStyle?: object,
    customContent?: any,
    disabled?: boolean
}

export default function DefaultBtn(props: DefaultBtnProps){
    const { text, customContent, backgroundColor, textColor, onClick, customStyle, disabled } = props;
    
    const style = {
        backgroundColor,
        color: textColor,
    }
    
    return (
        <button 
            className="defaultBtn" 
            style={Object.assign({}, style, customStyle)} 
            onClick={() => onClick()}
            disabled={disabled}
        >
            {customContent ? customContent : text}
        </button>
    )
} 