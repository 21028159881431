import { ChangeEventHandler } from 'react';
import './style.scss';

interface RangeInputProps {
    addFunction: Function,
    removeFunction: Function,
    onChange: ChangeEventHandler<HTMLInputElement>,
    value: number,
    customStyle?: object,
    buttonWidth?: number,
    fontSize?: number,
    disabled?: boolean
}

export default function RangeInput(props: RangeInputProps){
  const { addFunction, removeFunction, onChange, value, customStyle, buttonWidth, fontSize, disabled } = props;

  return (
    <div style={customStyle} className={`rangeInputContainer${disabled ? ' disabled' : ''}`}>
        <button style={{minWidth: `${buttonWidth}px`, fontSize: `${fontSize}px`}} onClick={() => removeFunction()}>-</button>
        <input 
            type="number"
            style={{fontSize: `${fontSize}px`}}
            value={value}
            onChange={onChange}
        />
        <button style={{minWidth: `${buttonWidth}px`, fontSize: `${fontSize}px`}} onClick={() => addFunction()}>+</button>
    </div>
  );
}
