import './style.scss';

interface EmptyListProps {
    title: string,
    subTitle: string
}

export default function EmptyList(props: EmptyListProps){
    const { title, subTitle } = props;
    
    return (
        <div>
            <h3>{title}</h3>
            <p>{subTitle}</p>
        </div>
    )
} 